import { beamColorsV2, darkColorsV2, lightColorsV2 } from 'uikit/theme/colorsV2'

export type CommonTheme = {
  connectButtonBackground: string
  connectButtonInnerBackground: string
  profileAction: string
  profileForeground: string
  closeButtonBackground: string
  modalBackground: string

  tableDropdownBtnBackground: string
  tableDropdownBtnColor: string
  tableDropdownBtnBorder: string
  tableDropdownArrowColor: string
  selectorBackground: string
}

export const light: CommonTheme = {
  connectButtonBackground: lightColorsV2.background,
  connectButtonInnerBackground: undefined,
  profileAction: undefined,
  profileForeground: undefined,
  closeButtonBackground: undefined,
  modalBackground: undefined,

  tableDropdownBtnBackground: lightColorsV2.text,
  tableDropdownBtnColor: lightColorsV2.main,
  tableDropdownBtnBorder: lightColorsV2.light,
  tableDropdownArrowColor: lightColorsV2.text,
  selectorBackground: lightColorsV2.main,
}

export const dark: CommonTheme = {
  connectButtonBackground: darkColorsV2.light,
  connectButtonInnerBackground: darkColorsV2.main3,
  profileAction: darkColorsV2.light,
  profileForeground: darkColorsV2.main,
  closeButtonBackground: darkColorsV2.dark2,
  modalBackground: darkColorsV2.main,

  tableDropdownBtnBackground: darkColorsV2.dark,
  tableDropdownBtnColor: darkColorsV2.textThirdly,
  tableDropdownBtnBorder: darkColorsV2.light,
  tableDropdownArrowColor: darkColorsV2.textThirdly,
  selectorBackground: darkColorsV2.light,
}

export const beam: CommonTheme = {
  connectButtonBackground: beamColorsV2.dark2,
  connectButtonInnerBackground: beamColorsV2.main3,
  profileAction: beamColorsV2.dark2,
  profileForeground: darkColorsV2.main,
  closeButtonBackground: darkColorsV2.dark2,
  modalBackground: darkColorsV2.main,

  tableDropdownBtnBackground: beamColorsV2.main,
  tableDropdownBtnColor: beamColorsV2.textSecondary,
  tableDropdownBtnBorder: beamColorsV2.light,
  tableDropdownArrowColor: beamColorsV2.textSecondary,
  selectorBackground: beamColorsV2.main,
}
