import { darkColorsV2, lightColorsV2, beamColorsV2 } from '../../theme/colorsV2'

export interface FooterTheme {
  text: string
  background: string
}

export const light: FooterTheme = {
  text: lightColorsV2.textSecondary,
  background: lightColorsV2.main2,
}

export const dark: FooterTheme = {
  text: darkColorsV2.textThirdly,
  background: darkColorsV2.main3,
}

export const beam: FooterTheme = {
  text: beamColorsV2.textSecondary,
  background: beamColorsV2.main3,
}
