import IFAllocationSale from 'config/abi/IFAllocationSale.json'
import IFAllocationMaster from 'config/abi/IFAllocationMaster.json'
import { filterFreeSale, filterNullSaleAddressSale, filterNullSaleAddressAndNFTSale } from 'utils/idosHelpers'
import BigNumber from 'bignumber.js'
import erc20ABI from 'config/abi/erc20.json'
import { PrivateIDOConfig, PaidStakeSaleConfig, FreeStakeDropConfig } from 'state/v2_types'
import { getAddress } from 'utils/addressHelpers'
import { BlockState } from 'state/types'
import { convertFromWei } from 'utils/formatBalance'
import { multicallPerChainId, reduceByChainId, reduceBySaleChainId } from 'utils/chainIdReducer'

export const fetchSalesUserHasWithdrawn = async (
  sales: (PrivateIDOConfig | PaidStakeSaleConfig)[],
  account: string,
) => {
  const nonNullableSales = filterNullSaleAddressAndNFTSale(sales)

  const callsPerChainId = reduceBySaleChainId(nonNullableSales, (sale) => {
    const { saleAddress } = sale
    return { address: saleAddress, name: 'hasWithdrawn', params: [account] }
  })

  const hasWithdrawn = await multicallPerChainId(IFAllocationSale, callsPerChainId)
  return hasWithdrawn
}

export const fetchSalesTotalPaymentReceived = async (sales: (PrivateIDOConfig | PaidStakeSaleConfig)[]) => {
  const nonNullableSales = filterNullSaleAddressSale(sales)
  const callsPerChainId = reduceBySaleChainId(nonNullableSales, (sale) => {
    const { saleAddress } = sale
    return { address: saleAddress, name: 'totalPaymentReceived', params: [] }
  })
  const rawPaymentReceived = await multicallPerChainId(IFAllocationSale, callsPerChainId)
  return Object.keys(rawPaymentReceived).reduce((acc, key) => {
    acc[key] = new BigNumber(rawPaymentReceived[key])
    return acc
  }, {})
}

export const fetchSalesPublicAllocation = async (sales: (PrivateIDOConfig | PaidStakeSaleConfig)[]) => {
  const nonNullableSales = filterNullSaleAddressSale(sales)
  const callsPerChainId = reduceBySaleChainId(nonNullableSales, (sale) => {
    const { saleAddress } = sale
    return { address: saleAddress, name: 'publicAllocation', params: [] }
  })
  const rawPaymentReceived = await multicallPerChainId(IFAllocationSale, callsPerChainId)
  return Object.keys(rawPaymentReceived).reduce((acc, key) => {
    acc[key] = new BigNumber(rawPaymentReceived[key])
    return acc
  }, {})
}

export const fetchSalesMaxTotalPurchasable = async (sales: (PrivateIDOConfig | PaidStakeSaleConfig)[]) => {
  const nonNullableSales = filterNullSaleAddressSale(sales)
  const callsPerChainId = reduceBySaleChainId(nonNullableSales, (sale) => {
    const { saleAddress } = sale
    return { address: saleAddress, name: 'maxTotalPurchasable', params: [] }
  })
  const rawPaymentReceived = await multicallPerChainId(IFAllocationSale, callsPerChainId)
  return Object.keys(rawPaymentReceived).reduce((acc, key) => {
    acc[key] = new BigNumber(rawPaymentReceived[key])
    return acc
  }, {})
}

export const fetchSalesPurchaserCount = async (sales: (PrivateIDOConfig | PaidStakeSaleConfig)[]) => {
  const nonNullableSales = filterNullSaleAddressAndNFTSale(sales)
  const callsPerChainId = reduceBySaleChainId(nonNullableSales, (sale) => {
    const { saleAddress } = sale
    return { address: saleAddress, name: 'purchaserCount', params: [] }
  })
  const rawPaymentReceived = await multicallPerChainId(IFAllocationSale, callsPerChainId)
  return Object.keys(rawPaymentReceived).reduce((acc, key) => {
    acc[key] = new BigNumber(rawPaymentReceived[key])
    return acc
  }, {})
}

export const fetchSalesBuybackClaimableNumber = async (sales: (PrivateIDOConfig | PaidStakeSaleConfig)[]) => {
  const nonNullableSales = filterNullSaleAddressAndNFTSale(sales)
  const callsPerChainId = reduceBySaleChainId(nonNullableSales, (sale) => {
    const { saleAddress } = sale
    return { address: saleAddress, name: 'buybackClaimableNumber', params: [] }
  })
  const rawPaymentReceived = await multicallPerChainId(IFAllocationSale, callsPerChainId)
  return Object.keys(rawPaymentReceived).reduce((acc, key) => {
    acc[key] = new BigNumber(rawPaymentReceived[key])
    return acc
  }, {})
}

export const fetchSalesUserPaymentReceived = async (
  sales: (PrivateIDOConfig | PaidStakeSaleConfig)[],
  account: string,
) => {
  const nonNullableSales = filterNullSaleAddressSale(sales)
  const callsPerChainId = reduceBySaleChainId(nonNullableSales, (sale) => {
    const { saleAddress } = sale
    return { address: saleAddress, name: 'paymentReceived', params: [account] }
  })
  const rawPaymentReceived = await multicallPerChainId(IFAllocationSale, callsPerChainId)
  return Object.keys(rawPaymentReceived).reduce((acc, key) => {
    acc[key] = new BigNumber(rawPaymentReceived[key])
    return acc
  }, {})
}

export const fetchSalesUserPaymentTokenBalances = async (
  sales: (PrivateIDOConfig | PaidStakeSaleConfig)[],
  account: string,
) => {
  const nonNullableSales = filterFreeSale(filterNullSaleAddressSale(sales))

  const callsPerChainId = reduceByChainId(nonNullableSales, (sale) => {
    const tokenAddress = getAddress(sale.paymentToken.address, sale.chainId)
    return {
      address: tokenAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicallPerChainId(erc20ABI, callsPerChainId)
  return Object.keys(rawTokenBalances).reduce((acc, key) => {
    acc[key] = new BigNumber(rawTokenBalances[key]).toJSON()
    return acc
  }, {})
}

export const fetchSaleUserStakingTokenBalances = async (
  sales: (FreeStakeDropConfig | PaidStakeSaleConfig)[],
  account: string,
) => {
  const nonNullableSales = sales.filter((val) => {
    return val.stakingToken && !!val.stakingToken?.address[val.chainId]
  })

  const callsPerChainId = reduceByChainId(nonNullableSales as (PaidStakeSaleConfig | PrivateIDOConfig)[], (sale) => {
    const { stakingToken } = sale
    const tokenAddress = getAddress(stakingToken.address, sale.chainId)
    return {
      address: tokenAddress,
      name: 'balanceOf',
      params: [account],
    }
  })

  const rawTokenBalances = await multicallPerChainId(erc20ABI, callsPerChainId)
  return Object.keys(rawTokenBalances).reduce((acc, key) => {
    acc[key] = new BigNumber(rawTokenBalances[key]).toJSON()
    return acc
  }, {})
}

export const fetchSaleStakingUserAllowances = async (
  sales: (FreeStakeDropConfig | PaidStakeSaleConfig)[],
  account: string,
) => {
  const nonNullableSales = sales.filter((val) => val.masterAddress && val.stakingToken)

  const callsPerChainId = reduceByChainId(nonNullableSales as (PaidStakeSaleConfig | PrivateIDOConfig)[], (sale) => {
    const { masterAddress, stakingToken, chainId } = sale
    const lpContractAddress = getAddress(stakingToken.address, chainId)
    return { address: lpContractAddress, name: 'allowance', params: [account, masterAddress] }
  })

  const rawAllowances = await multicallPerChainId(erc20ABI, callsPerChainId)
  return Object.keys(rawAllowances).reduce((acc, key) => {
    acc[key] = new BigNumber(rawAllowances[key]).toJSON()
    return acc
  }, {})
}

export const fetchSaleTotalStakeWeights = async (
  sales: (FreeStakeDropConfig | PaidStakeSaleConfig)[],
  blocks: { [key: number]: BlockState },
) => {
  const nonNullableSales = sales.filter((val) => val.masterAddress && 'subscribePeriod' in val)

  const omniAddedSales = nonNullableSales.map((sale) => {
    return {
      ...sale,
      chainId: sale.chainId === 421613 || sale.chainId === 59140 ? 165 : sale.chainId,
      masterAddress:
        sale.chainId === 421613 || sale.chainId === 59140
          ? '0x77D60153561FCb8bB8304B3eB61f54960626e5dD'
          : sale.masterAddress,
    }
  })

  const callsPerChainId = reduceByChainId(omniAddedSales as (PaidStakeSaleConfig | PrivateIDOConfig)[], (sale) => {
    const { masterAddress, trackId, chainId } = sale
    const { currentBlock } = blocks[chainId]
    const allocSnapshotBlock = sale.subscribePeriod.allocSnapshotBlock ?? Number.MAX_SAFE_INTEGER
    const blockNumber = Math.min(allocSnapshotBlock, Math.max(0, currentBlock - 10))

    return {
      address: masterAddress,
      name: 'getTotalStakeWeight',
      params: [trackId, blockNumber],
    }
  })

  const rawUserStakeWeights = await multicallPerChainId(IFAllocationMaster.abi, callsPerChainId)

  const callsPerChainIdV2 = reduceByChainId(omniAddedSales as (PaidStakeSaleConfig | PrivateIDOConfig)[], (sale) => {
    const { masterAddress, trackId, subscribePeriod } = sale
    const { endTime } = subscribePeriod

    const validSnapshotTimestamp = Math.min(
      Math.floor(new Date(endTime).getTime() / 1000),
      Math.floor(new Date().getTime() / 1000) - 10,
    )

    return {
      address: masterAddress,
      name: 'getTotalStakeWeight',
      params: [trackId, validSnapshotTimestamp],
    }
  })
  const rawUserStakeWeightsV2 = await multicallPerChainId(IFAllocationMaster.abi, callsPerChainIdV2)

  return Object.keys(rawUserStakeWeightsV2).reduce((acc, key) => {
    acc[key] = new BigNumber(rawUserStakeWeightsV2[key] ?? rawUserStakeWeights[key]).toJSON()
    return acc
  }, {})
}

export const fetchSaleUserStakeWeights = async (
  sales: (FreeStakeDropConfig | PaidStakeSaleConfig)[],
  account: string,
  blocks: { [key: number]: BlockState },
) => {
  const nonNullableSales = sales.filter((val) => val.masterAddress && 'subscribePeriod' in val)

  const callsPerChainId = reduceByChainId(nonNullableSales as (PaidStakeSaleConfig | PrivateIDOConfig)[], (sale) => {
    const { masterAddress, trackId, chainId } = sale
    const { currentBlock } = blocks[chainId]
    const allocSnapshotBlock = sale.subscribePeriod.allocSnapshotBlock ?? Number.MAX_SAFE_INTEGER
    const blockNumber = Math.min(allocSnapshotBlock, Math.max(0, currentBlock - 10))

    return {
      address: masterAddress,
      name: 'getUserStakeWeight',
      params: [trackId, account, blockNumber],
    }
  })

  const rawUserStakeWeights = await multicallPerChainId(IFAllocationMaster.abi, callsPerChainId)
  return Object.keys(rawUserStakeWeights).reduce((acc, key) => {
    acc[key] = new BigNumber(rawUserStakeWeights[key]).toJSON()
    return acc
  }, {})
}

export const fetchSaleUserStakeWeightsV2 = async (
  sales: (FreeStakeDropConfig | PaidStakeSaleConfig)[],
  account: string,
) => {
  const nonNullableSales = sales.filter((val) => val.masterAddress && 'subscribePeriod' in val)

  const omniAddedSales = nonNullableSales.map((sale) => {
    return {
      ...sale,
      chainId: sale.chainId === 421613 || sale.chainId === 59140 ? 165 : sale.chainId,
      masterAddress:
        sale.chainId === 421613 || sale.chainId === 59140
          ? '0x77D60153561FCb8bB8304B3eB61f54960626e5dD'
          : sale.masterAddress,
    }
  })

  const callsPerChainId = reduceByChainId(omniAddedSales as (PaidStakeSaleConfig | PrivateIDOConfig)[], (sale) => {
    const { masterAddress, trackId, subscribePeriod } = sale
    const { endTime } = subscribePeriod

    const validSnapshotTimestamp = Math.min(
      Math.floor(new Date(endTime).getTime() / 1000),
      Math.floor(new Date().getTime() / 1000) - 10,
    )

    return {
      address: masterAddress,
      name: 'getUserStakeWeight',
      params: [trackId, account, validSnapshotTimestamp],
    }
  })

  const rawUserStakeWeights = await multicallPerChainId(IFAllocationMaster.abi, callsPerChainId)
  return Object.keys(rawUserStakeWeights).reduce((acc, key) => {
    acc[key] = new BigNumber(rawUserStakeWeights[key]).toJSON()
    return acc
  }, {})
}

export const fetchSaleUserStakeAmounts = async (
  sales: (FreeStakeDropConfig | PaidStakeSaleConfig)[],
  account: string,
) => {
  const nonNullableSales = sales.filter((val) => val.masterAddress && val.masterAddress && 'subscribePeriod' in val)

  let callsPerChainId = reduceByChainId(nonNullableSales as (PaidStakeSaleConfig | PrivateIDOConfig)[], (sale) => {
    const { masterAddress, trackId } = sale
    return {
      address: masterAddress,
      name: 'userCheckpointCounts',
      params: [trackId, account],
    }
  })

  const userCheckpointCounts = await multicallPerChainId(IFAllocationMaster.abi, callsPerChainId)
  const salesWithValidUserCheckpointCount = sales.filter(
    (sale) => userCheckpointCounts[sale.id] && userCheckpointCounts[sale.id][0] > 0,
  )
  callsPerChainId = reduceByChainId(
    salesWithValidUserCheckpointCount as (PaidStakeSaleConfig | PrivateIDOConfig)[],
    (sale) => {
      const { masterAddress, trackId, id } = sale
      return {
        address: masterAddress,
        name: 'userCheckpoints',
        params: [trackId, account, userCheckpointCounts[id][0] - 1],
      }
    },
  )
  const userStakeAmounts = await multicallPerChainId(IFAllocationMaster.abi, callsPerChainId)
  return sales.reduce((acc, sale) => {
    acc[sale.id] = convertFromWei(
      new BigNumber([userStakeAmounts[sale.id]?.staked ?? 0] as any),
      sale.stakingToken.decimals,
    )
    return acc
  }, {})
}

export const fetchSalePaymentUserAllowances = async (
  sales: (PrivateIDOConfig | PaidStakeSaleConfig)[],
  account: string,
) => {
  const nonNullableSales = filterFreeSale(filterNullSaleAddressSale(sales))
  const callsPerChainId = reduceBySaleChainId(
    nonNullableSales as (PaidStakeSaleConfig | PrivateIDOConfig)[],
    (sale) => {
      const lpContractAddress = getAddress(sale.paymentToken.address, sale.chainId)
      const { saleAddress } = sale
      return { address: lpContractAddress, name: 'allowance', params: [account, saleAddress] }
    },
  )

  const rawAllowances = await multicallPerChainId(erc20ABI, callsPerChainId)
  return Object.keys(rawAllowances).reduce((acc, key) => {
    acc[key] = new BigNumber(rawAllowances[key]).toJSON()
    return acc
  }, {})
}

export const fetchUserFinalTokenAllocations = async (
  sales: (PrivateIDOConfig | PaidStakeSaleConfig)[],
  account: string,
) => {
  const nonNullableSales = filterNullSaleAddressAndNFTSale(sales)

  const callsPerChainId = reduceBySaleChainId(
    nonNullableSales as (PaidStakeSaleConfig | PrivateIDOConfig)[],
    (sale) => {
      const { saleAddress } = sale
      return { address: saleAddress, name: 'getUserStakeValue', params: [account] }
    },
  )

  const rawUserTokenAllocation = await multicallPerChainId(IFAllocationSale, callsPerChainId)

  return Object.keys(rawUserTokenAllocation).reduce((acc, key) => {
    acc[key] = new BigNumber(rawUserTokenAllocation[key]).toJSON()
    return acc
  }, {})
}

export const fetchUserCurrentClaimableToken = async (
  sales: (PrivateIDOConfig | PaidStakeSaleConfig)[],
  account: string,
) => {
  const nonNullableSales = filterNullSaleAddressAndNFTSale(sales)

  const callsPerChainId = reduceBySaleChainId(nonNullableSales, (sale) => {
    const { saleAddress } = sale
    return { address: saleAddress, name: 'getCurrentClaimableToken', params: [account] }
  })
  const currentClaimableTokens = await multicallPerChainId(IFAllocationSale, callsPerChainId)
  return currentClaimableTokens
}

export const fetchUserOptIn = async (sales: (PrivateIDOConfig | PaidStakeSaleConfig)[], account: string) => {
  const nonNullableSales = filterNullSaleAddressAndNFTSale(sales)

  const callsPerChainId = reduceBySaleChainId(nonNullableSales, (sale) => {
    const { saleAddress } = sale
    return { address: saleAddress, name: 'hasOptInBuyback', params: [account] }
  })
  const hasOptedIn = await multicallPerChainId(IFAllocationSale, callsPerChainId)
  return hasOptedIn
}

export const fetchUserStakedOnCurrentChain = async (
  sales: (FreeStakeDropConfig | PaidStakeSaleConfig)[],
  account: string,
) => {
  const nonNullableSales = sales.filter((val) => val.masterAddress && val.masterAddress && 'subscribePeriod' in val)

  const callsPerChainId = reduceByChainId(nonNullableSales, (sale) => {
    const { trackId, masterAddress } = sale
    return { address: masterAddress, name: 'userStakedOnCurrentChain', params: [trackId, account] }
  })

  const stakedTokens = await multicallPerChainId(IFAllocationMaster.abi, callsPerChainId)
  return stakedTokens
}
