import React, { useCallback, useState } from 'react'
import {
  Button,
  Text,
  ChevronUpIcon,
  ChevronDownIcon,
  Card,
  Flex,
  CardProps,
  AddIcon,
  MinusIcon,
  useMatchBreakpoints,
} from 'uikit'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { useTranslation } from 'contexts/Localization'
import { RowBetween, RowFixed } from 'componentsV2/layout/Row'
import { Pair } from 'swap-sdk/entities/pair'
import { registerToken } from 'utils/wallet'
import { useAccount, useNetwork } from 'wagmi'
import { useTokenBalance } from 'hooks/useWallet'
import JSBI from 'jsbi'
import { Percent } from 'swap-sdk/entities/fractions/percent'
import { AutoColumn } from 'componentsV2/layout/Column'
import { BIG_INT_ZERO } from 'config/constants'
import useTheme from 'hooks/useTheme'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import useTotalSupply from '../../hooks/useTotalSupply'

import { currencyId } from '../../utils/currencyId'
import { unwrappedToken } from '../../utils/wrappedCurrency'

import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'
import Dots from '../Loader/Dots'
import { TokenClass } from 'swap-sdk/entities/token'
import getGasToken from 'utils/getGasToken'

const FixedHeightRow = styled(RowBetween)`
  height: 24px;
`

const StyledCard = styled.div<{ withSolidBorder?: boolean }>`
  padding: 20px 30px;
  width: 100%;
  max-width: 350px;
  margin-top: 20px;
  border-radius: 10px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  transition: transform 300ms ease-in-out;
  border: ${({ theme, withSolidBorder }) => withSolidBorder && `solid 1px ${theme.colorsV2?.light}`};

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border-radius: 20px;
    background: ${({ theme }) => theme.colorsV2?.gradients?.swapBorder}
    z-index: -2;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;

    border-radius: 20px;
    background: ${({ theme }) => theme.colorsV2?.swap?.background};
    z-index: -1;
  }
`

interface PositionCardProps extends CardProps {
  pair: Pair
  showUnwrapped?: boolean
  withSolidBorder?: boolean
}

export function MinimalPositionCard({ pair, showUnwrapped = false, withSolidBorder }: PositionCardProps) {
  const { address: account } = useAccount()

  const { chain } = useNetwork()

  const { t } = useTranslation()

  const currency0 = showUnwrapped ? pair.token0 : unwrappedToken(pair.token0)
  const currency1 = showUnwrapped ? pair.token1 : unwrappedToken(pair.token1)

  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  const token0 = currency0.address
    ? new TokenClass(chain?.id, currency0.address, currency0.decimals, currency0.symbol)
    : getGasToken(chain?.id)
  const token1 = currency1.address
    ? new TokenClass(chain?.id, currency1.address, currency1.decimals, currency1.symbol)
    : getGasToken(chain?.id)

  const registerTokenCallback = useCallback(
    () => registerToken(Pair.getAddress(token0, token1), `${token0.symbol}/${token1.symbol}`, 18),
    [token0, token1],
  )

  return (
    <>
      {userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, JSBI.BigInt(0)) && (
        <StyledCard withSolidBorder={withSolidBorder}>
          <AutoColumn gap="16px">
            <FixedHeightRow>
              <RowFixed>
                <IFTypography variant="body2" fontWeight="bold">
                  {t('LP tokens in your wallet')}
                </IFTypography>
              </RowFixed>
            </FixedHeightRow>
            <FixedHeightRow onClick={() => setShowMore(!showMore)}>
              <RowFixed>
                <DoubleCurrencyLogo currency0={currency0} currency1={currency1} overlap size={26} />
                <Flex ml="11px" />
                <IFTypography variant="body2">
                  {currency0.symbol}-{currency1.symbol} LP
                </IFTypography>
              </RowFixed>
              <RowFixed>
                <IFTypography variant="body2">{userPoolBalance ? userPoolBalance.toSignificant(4) : '-'}</IFTypography>
              </RowFixed>
            </FixedHeightRow>
            <AutoColumn gap="4px">
              <FixedHeightRow>
                <IFTypography variant="body2">{t('Share of Pool')}</IFTypography>
                <IFTypography variant="body2">
                  {poolTokenPercentage ? `${poolTokenPercentage.toFixed(6)}%` : '-'}
                </IFTypography>
              </FixedHeightRow>
              <FixedHeightRow>
                <IFTypography variant="body2">{t('Pooled %asset%', { asset: currency0.symbol })}</IFTypography>
                {token0Deposited ? (
                  <RowFixed>
                    <Text ml="6px" fontSize="14px">
                      {token0Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
              <FixedHeightRow>
                <IFTypography variant="body2">{t('Pooled %asset%', { asset: currency1.symbol })}</IFTypography>
                {token1Deposited ? (
                  <RowFixed>
                    <Text fontSize="14px" ml="6px">
                      {token1Deposited?.toSignificant(6)}
                    </Text>
                  </RowFixed>
                ) : (
                  '-'
                )}
              </FixedHeightRow>
            </AutoColumn>
            <RowFixed>
              <Button variant="text" onClick={registerTokenCallback}>
                Add {currency0.symbol}-{currency1.symbol} LP to your wallet
              </Button>
            </RowFixed>
          </AutoColumn>
        </StyledCard>
      )}
    </>
  )
}

export default function FullPositionCard({ pair, ...props }: PositionCardProps) {
  const { address: account } = useAccount()

  const currency0 = unwrappedToken(pair.token0)
  const currency1 = unwrappedToken(pair.token1)
  const { theme } = useTheme()
  const { t } = useTranslation()
  const [showMore, setShowMore] = useState(false)

  const userPoolBalance = useTokenBalance(account ?? undefined, pair.liquidityToken)
  const totalPoolTokens = useTotalSupply(pair.liquidityToken)
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  const poolTokenPercentage =
    !!userPoolBalance && !!totalPoolTokens && JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? new Percent(userPoolBalance.raw, totalPoolTokens.raw)
      : undefined

  const [token0Deposited, token1Deposited] =
    !!pair &&
    !!totalPoolTokens &&
    !!userPoolBalance &&
    // this condition is a short-circuit in the case where useTokenBalance updates sooner than useTotalSupply
    JSBI.greaterThanOrEqual(totalPoolTokens.raw, userPoolBalance.raw)
      ? [
          pair.getLiquidityValue(pair.token0, totalPoolTokens, userPoolBalance, false),
          pair.getLiquidityValue(pair.token1, totalPoolTokens, userPoolBalance, false),
        ]
      : [undefined, undefined]

  return (
    <Card
      style={{
        borderRadius: '10px',
        background: theme.isDark ? theme.colorsV2?.dark2 : theme.colorsV2?.main,
        border: `solid 1px ${theme.colorsV2?.light}`,
      }}
      {...props}
    >
      <Flex justifyContent="space-between" role="button" onClick={() => setShowMore(!showMore)} p="16px">
        <Flex flexDirection="column">
          <Flex alignItems="center" mb="4px">
            <DoubleCurrencyLogo currency0={currency0} currency1={currency1} size={20} overlap />
            <Flex mr="5px" />
            <IFTypography fontWeight="bold" variant="body2">
              {!currency0 || !currency1 ? <Dots>Loading</Dots> : `${currency0.symbol}/${currency1.symbol}`}
            </IFTypography>
          </Flex>
        </Flex>
        {showMore ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </Flex>

      {showMore && (
        <AutoColumn gap="8px" style={{ padding: '0 16px 16px' }}>
          <FixedHeightRow>
            <RowFixed>
              <IFTypography ifcolor="textSecondary" variant="caption">
                Pooled {currency0.symbol}
              </IFTypography>
              <Flex mr="5px" />
              <CurrencyLogo size="20px" currency={currency0} />
            </RowFixed>
            {token0Deposited ? (
              <RowFixed>
                <IFTypography variant="body2" fontWeight="bold">
                  {token0Deposited?.toSignificant(6)}
                </IFTypography>
              </RowFixed>
            ) : (
              '-'
            )}
          </FixedHeightRow>

          <FixedHeightRow>
            <RowFixed>
              <IFTypography ifcolor="textSecondary" variant="caption">
                Pooled {currency1.symbol}
              </IFTypography>
              <Flex mr="5px" />
              <CurrencyLogo size="20px" currency={currency1} />
            </RowFixed>
            {token1Deposited ? (
              <RowFixed>
                <IFTypography variant="body2" fontWeight="bold">
                  {token1Deposited?.toSignificant(6)}
                </IFTypography>
              </RowFixed>
            ) : (
              '-'
            )}
          </FixedHeightRow>
          <FixedHeightRow>
            <IFTypography ifcolor="textSecondary" variant="caption">
              {t('Your pool tokens')}
            </IFTypography>
            <IFTypography variant="body2" fontWeight="bold">
              {userPoolBalance?.toSignificant(4)}
            </IFTypography>
          </FixedHeightRow>
          <FixedHeightRow>
            <IFTypography ifcolor="textSecondary" variant="caption">
              Share of pool
            </IFTypography>
            <IFTypography variant="body2" fontWeight="bold">
              {poolTokenPercentage
                ? `${poolTokenPercentage.toFixed(2) === '0.00' ? '<0.01' : poolTokenPercentage.toFixed(2)}%`
                : '-'}
            </IFTypography>
          </FixedHeightRow>

          {userPoolBalance && JSBI.greaterThan(userPoolBalance.raw, BIG_INT_ZERO) && (
            <Flex flexDirection="row" justifyContent={isMobile ? 'space-between' : 'flex-end'} mt="10px">
              <Button
                as={Link}
                to={`/liquidity/remove/${currencyId(currency0)}/${currencyId(currency1)}`}
                variant="thirdly"
                mb="8px"
                scale="sm"
                width={isMobile ? '116px' : '83px'}
                height={isMobile ? '32px' : '26px'}
                style={{ borderRadius: 21 }}
              >
                <MinusIcon
                  width="10px"
                  mr="4px"
                  color={theme.isDark ? theme.colorsV2.button.primaryText : theme.colorsV2?.main}
                />
                <Text fontSize="12px" fontWeight={700} color="#FFFFFF">
                  Remove
                </Text>
              </Button>
              <Button
                as={Link}
                to={`/liquidity/add/${currencyId(currency0)}/${currencyId(currency1)}`}
                variant="thirdly"
                scale="sm"
                ml="18px"
                width={isMobile ? '116px' : '83px'}
                height={isMobile ? '32px' : '26px'}
                style={{ borderRadius: 21 }}
              >
                <AddIcon
                  width="10px"
                  mr="4px"
                  color={theme.isDark ? theme.colorsV2.button.primaryText : theme.colorsV2?.main}
                />
                <Text fontSize="12px" fontWeight={700} color="#FFFFFF">
                  Add
                </Text>
              </Button>
            </Flex>
          )}
        </AutoColumn>
      )}
    </Card>
  )
}
