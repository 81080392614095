export type Gradients = {
  bubblegum: string
  green2lightblue: string
  blue2indigo: string
  buttonNormal: string
  buttonHover: string
  buttonLoading: string
  swapBorder: string
}

export type Feedbacks = {
  successText: string
  successBg: string
  dangerText: string
  dangerBg: string
  warningText: string
  warningBg: string
  infoText: string
  infoBg: string
  disableText: string
  disableBg: string
}

export type Shadows = {
  settings: string
  navbar: string
}

export type Status = {
  open: string
  completed: string
  closed: string
}

export type Difficulty = {
  beginner: string
  intermediate: string
  advanced: string
}

type RoiCalculator = {
  background: string
  footerBackground: string
  footerHeaderText: string
  footerDetailsText: string
}

export type ColorsV2 = {
  background: string
  secondary: string
  main: string
  main2: string
  main3: string
  main4: string
  mainNonTransparent: string
  dark: string
  dark2: string
  light: string
  lightGreen: string
  loadingFirst: string
  loadingSecond: string
  text: string
  textSecondary: string
  textThirdly: string
  textLink: string
  textDisabled: string
  textInfo: string
  textWarning: string
  textDanger: string
  textButton: string
  success: string
  danger: string
  warningBg: string
  tagBg: string
  tagText: string
  exploreIndicator: string
  exploreIndicatorActive: string
  saleEnds: string
  saleLive: string
  // Gradients
  gradients: Gradients

  // Feedbacks
  feedbacks: Feedbacks

  // InfoCard
  infoCard: InfoCard

  // Button
  button: Button

  // swap card
  swap: {
    background: string
    migrationBannerBackground: string
    migrationBannerBtnColor: string
  }

  // Shadows
  shadows: Shadows

  // quest question status
  statusPill: {
    background: Status
    text: Status
  }
  difficultyPill: {
    background: Difficulty
    text: Difficulty
  }
  roiCalculator: RoiCalculator
}

type InfoCard = {
  infoText: string
  infoBg: string
}

type Button = {
  primaryBg: string
  secondaryBg: string
  tertiaryBg: string
  quaternaryBg: string
  dangerBg: string
  disabledBg: string
  primaryText: string
  quaternaryText: string
  dangerText: string
  linkText: string
  disabledText: string
  radioOptionBg: string
  radioOptionText: string
  radioSelectedBg: string
  radioSelectedText: string
}

export const lightColorsV2: ColorsV2 = {
  background: '#FFFFFF',
  secondary: '#00FFB9',
  main: '#FFFFFF',
  main2: '#FFFFFFCC',
  main3: '#F9EEFF80',
  main4: '#00DAFF',
  mainNonTransparent: '#FFFFFF',
  dark: '#F2F7FA',
  dark2: '#F2F2F2',
  light: '#E0E0E0',
  lightGreen: '#050A5A',
  loadingFirst: '#D4DEE3',
  loadingSecond: '#C7D5DB',
  text: '#5A5A5A',
  textSecondary: '#5A5A5AB2',
  textThirdly: '#B67CFF',
  textLink: '#0074FF',
  textDisabled: '#77777780',
  textInfo: '#4F8AE3',
  textWarning: '#F0AF41',
  textButton: '#FFF',
  textDanger: '#ED756E',
  success: '#56A944',
  danger: '#ED756E',
  warningBg: '#FEF8EE',
  tagBg: '#4F8AE3CC',
  tagText: '#DEEFFF',
  exploreIndicator: '#459DEE',
  exploreIndicatorActive: '#459DEE',
  saleLive: '#B67CFF',
  saleEnds: '#77777780',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #00DAFF 0%, #00FFB9 100%)',
    green2lightblue: 'linear-gradient(90deg, #00CB82 -2.21%, #00B4ED 100%)',
    blue2indigo: 'linear-gradient(90deg, #3580FE -2.21%, #A633FE 100%)',
    buttonNormal: 'linear-gradient(90deg, #00CB82 -2.21%, #00B4ED 100%)',
    buttonHover: 'linear-gradient(90deg, rgba(0, 203, 130, 0.5) -2.21%, rgba(0, 180, 237, 0.5) 100%)',
    buttonLoading: 'linear-gradient(90deg, rgba(0, 203, 130, 0.5) -2.21%, rgba(0, 180, 237, 0.5) 100%)',
    swapBorder: 'linear-gradient(90deg, #77acff -2.21%, #c578ff 89.35%);',
  },
  feedbacks: {
    successText: '#56A944',
    successBg: '#C8EFD04D',
    dangerText: '#ED756E',
    dangerBg: '#FFB2C04D',
    warningText: '#F0AF41',
    warningBg: '#F1F2A9',
    infoText: '#4F8AE3',
    infoBg: '#DEEFFF',
    disableText: '#FFFFFF80',
    disableBg: '#E0E0E0',
  },
  infoCard: {
    infoText: '#4F8AE3',
    infoBg: '#DEEFFF',
  },
  button: {
    primaryBg: 'linear-gradient(90deg, #3580FE -2.21%, #A633FE 100%)',
    secondaryBg: '#77777780',
    tertiaryBg: '#15152E99',
    quaternaryBg: '#DEEFFF',
    quaternaryText: '#4F8AE3',
    dangerBg: '#FFB2C04D',
    disabledBg: '#E0E0E0',
    primaryText: '#FFFFFF',
    dangerText: '#ED756E',
    linkText: '#0074FF',
    disabledText: '#77777780',
    radioOptionBg: '#F2F2F2',
    radioOptionText: '#5A5A5AB2',
    radioSelectedBg: '#0AC6E5',
    radioSelectedText: '#FAFAFA',
  },
  swap: {
    background: '#FFFFFF',
    migrationBannerBackground: 'linear-gradient(90.2deg, #EFE7F3 26.53%, #E0E2F7 71.19%)',
    migrationBannerBtnColor: '#FFFFFF',
  },
  shadows: {
    settings: '0px 0px 25px #d4cbdca6',
    navbar: '0px 4px 20px 0px #003D9814',
  },
  statusPill: {
    background: {
      open: '#4F8AE3',
      completed: '#56A944',
      closed: '#E0E0E0',
    },
    text: {
      open: '#FFFFFF',
      completed: '#FFFFFF',
      closed: '#777777',
    },
  },
  difficultyPill: {
    background: {
      beginner: '#C8EFD04D',
      intermediate: '#FEF8EE',
      advanced: '#FFB3C0',
    },
    text: {
      beginner: '#56A944',
      intermediate: '#F5B452',
      advanced: '#ED756E',
    },
  },
  roiCalculator: {
    background: '#FFFFFF',
    footerBackground: '#F5FAFF',
    footerHeaderText: '#4F8AE3',
    footerDetailsText: '#4F8AE3',
  },
}

export const darkColorsV2: ColorsV2 = {
  background: '#F2F4F5',
  secondary: '#00FFB9',
  main: '#2E3466',
  main2: '#2E3466CC',
  main3: '#2E346666',
  main4: '#00DAFF',
  mainNonTransparent: '#282d5a',
  dark: '#15152E',
  dark2: '#15152E99',
  light: '#555A9B',
  lightGreen: '#050A5A',
  loadingFirst: '#555A9B',
  loadingSecond: '#ACACBB',
  text: '#FAFAFA',
  textSecondary: '#FAFAFAB2',
  textThirdly: '#BDC2FF',
  textLink: '#0AC6E5',
  textDisabled: '#555A9B',
  textWarning: '#F0AF41',
  textButton: '#FFF',
  textDanger: '#F1878D',
  textInfo: '#24C7EB',
  success: '#00D279',
  tagBg: '#2E3466CC',
  tagText: '#FAFAFAB2',
  danger: '#FF8799',
  warningBg: '#58535A',
  exploreIndicator: '#555A9B',
  exploreIndicatorActive: '#BDC2FF',
  saleLive: '#BDC2FF',
  saleEnds: '#555A9B',

  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #00DAFF 0%, #00FFB9 100%)',
    green2lightblue: 'linear-gradient(90deg, #12ECB8 -2.21%, #00B4ED 92.02%)',
    blue2indigo: 'linear-gradient(90deg, #0063FF -2.21%, #9100FF 89.35%)',
    buttonNormal: 'linear-gradient(90deg, #00CB82 -2.21%, #00B4ED 100%)',
    buttonHover: 'linear-gradient(90deg, rgba(0, 203, 130, 0.5) -2.21%, rgba(0, 180, 237, 0.5) 100%)',
    buttonLoading: 'linear-gradient(90deg, rgba(0, 203, 130, 0.5) -2.21%, rgba(0, 180, 237, 0.5) 100%)',
    swapBorder: 'linear-gradient(146.07deg, #5094FF 4.09%, #0063FF 9.96%, #810BFF 85.5%, #6500B3 94.55%);',
  },

  feedbacks: {
    successText: '#00D279',
    successBg: '#0B58204D',
    dangerText: '#FF8799',
    dangerBg: '#580B0B4D',
    warningText: '#F0AF41',
    warningBg: '#F0AF4199',
    infoText: '#24C7EB',
    infoBg: '#005FB74D',
    disableText: '#FAFAFA',
    disableBg: '#555A9B',
  },
  infoCard: {
    infoBg: '#BDC2FF0F',
    infoText: '#BDC2FF',
  },
  button: {
    primaryBg: 'linear-gradient(90deg, #0063FF -2.21%, #9100FF 89.35%)',
    secondaryBg: '#555A9B',
    tertiaryBg: '#15152E99',
    quaternaryBg: '#BDC2FF80',
    quaternaryText: '#FFFFFF',
    dangerBg: '#580B0B4D',
    disabledBg: '#555A9B',
    primaryText: '#FFFFFF',
    linkText: '#0AC6E5',
    dangerText: '#FF8799',
    disabledText: '#FAFAFA4D',
    radioOptionBg: '#555A9B',
    radioOptionText: '#FAFAFA',
    radioSelectedBg: '#0AC6E5',
    radioSelectedText: '#FAFAFA',
  },
  swap: {
    background: '#2E3466',
    migrationBannerBackground: 'linear-gradient(90.14deg, #1B3457 27.85%, #2A1A48 71.77%)',
    migrationBannerBtnColor: '#2E3466',
  },
  shadows: {
    settings: '0px 0px 25px #1D003A',
    navbar: '0px 1px 0px 0px #2B3160',
  },
  statusPill: {
    background: {
      open: '#24C7EB',
      completed: '#00D279',
      closed: '#555A9B',
    },
    text: {
      open: '#2E3466',
      completed: '#2E3466',
      closed: '#868AB7',
    },
  },
  difficultyPill: {
    background: {
      beginner: '#0B58204D',
      intermediate: '#58545B',
      advanced: '#3A284B',
    },
    text: {
      beginner: '#00D279',
      intermediate: '#F0AF41',
      advanced: '#FF8799',
    },
  },
  roiCalculator: {
    background: '#2E3466',
    footerBackground: '#1F2144',
    footerHeaderText: '#FAFAFA',
    footerDetailsText: '#B8B9C3',
  },
}

export const beamColorsV2: ColorsV2 = {
  background: '#1A1B1F',
  secondary: '#00FFB9',
  main: '#282A33',
  main2: '#14151A',
  main3: '#20222A',
  main4: '#6976CD',
  mainNonTransparent: '#14151A',
  dark: '#1A1B1F',
  dark2: '#383F5B',
  light: '#596187',
  lightGreen: '#050A5A',
  loadingFirst: '#555A9B',
  loadingSecond: '#ACACBB',
  text: '#FAFAFA',
  textSecondary: '#91969A',
  textThirdly: '#6976CD',
  textLink: '#8692FF',
  textDisabled: '#444E60',
  textWarning: '#F0AF41',
  textButton: '#FFF',
  textDanger: '#F1878D',
  textInfo: '#24C7EB',
  success: '#00D279',
  tagBg: '#2E3466CC',
  tagText: '#FAFAFAB2',
  danger: '#FF8799',
  warningBg: '#58535A',
  exploreIndicator: '#555A9B',
  exploreIndicatorActive: '#BDC2FF',
  saleEnds: '#444E60',
  saleLive: '444E60',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #00DAFF 0%, #00FFB9 100%)',
    green2lightblue: 'linear-gradient(90deg, #12ECB8 -2.21%, #00B4ED 92.02%)',
    blue2indigo: 'linear-gradient(90deg, #0063FF -2.21%, #9100FF 89.35%)',
    buttonNormal: 'linear-gradient(90deg, #00CB82 -2.21%, #00B4ED 100%)',
    buttonHover: 'linear-gradient(90deg, rgba(0, 203, 130, 0.5) -2.21%, rgba(0, 180, 237, 0.5) 100%)',
    buttonLoading: 'linear-gradient(90deg, rgba(0, 203, 130, 0.5) -2.21%, rgba(0, 180, 237, 0.5) 100%)',
    swapBorder: 'linear-gradient(146.07deg, #5094FF 4.09%, #0063FF 9.96%, #810BFF 85.5%, #6500B3 94.55%);',
  },

  feedbacks: {
    successText: '#6EC25C',
    successBg: '#0B58204D',
    dangerText: '#ED756E',
    dangerBg: '#580B0B4D',
    warningText: '#F5B452',
    warningBg: '#F0AF414D',
    infoText: '#4F8AE3',
    infoBg: '#005FB74D',
    disableText: '#FFFFFF4D',
    disableBg: '#444E60',
  },
  infoCard: {
    infoBg: '#BDC2FF0F',
    infoText: '#BDC2FF',
  },
  button: {
    primaryBg: 'linear-gradient(180deg, #434D76 0%, #5B629E 100%)',
    secondaryBg: '#555A9B',
    tertiaryBg: '#15152E99',
    quaternaryBg: '#BDC2FF80',
    quaternaryText: '#FFFFFF',
    dangerBg: '#580B0B4D',
    disabledBg: '#555A9B',
    primaryText: '#FFFFFF',
    linkText: '#0AC6E5',
    dangerText: '#FF8799',
    disabledText: '#FAFAFA4D',
    radioOptionBg: '#555A9B',
    radioOptionText: '#FAFAFA',
    radioSelectedBg: '#0AC6E5',
    radioSelectedText: '#FAFAFA',
  },
  swap: {
    background: '#2E3466',
    migrationBannerBackground: 'linear-gradient(90.14deg, #1B3457 27.85%, #2A1A48 71.77%)',
    migrationBannerBtnColor: '#2E3466',
  },
  shadows: {
    settings: '0px 0px 25px #1D003A',
    navbar: '0px 1px 0px 0px #2B3160',
  },
  statusPill: {
    background: {
      open: '#24C7EB',
      completed: '#00D279',
      closed: '#555A9B',
    },
    text: {
      open: '#2E3466',
      completed: '#2E3466',
      closed: '#868AB7',
    },
  },
  difficultyPill: {
    background: {
      beginner: '#0B58204D',
      intermediate: '#58545B',
      advanced: '#3A284B',
    },
    text: {
      beginner: '#00D279',
      intermediate: '#F0AF41',
      advanced: '#FF8799',
    },
  },
  roiCalculator: {
    background: '#2E3466',
    footerBackground: '#1F2144',
    footerHeaderText: '#FAFAFA',
    footerDetailsText: '#B8B9C3',
  },
}

export const aethirColorsV2: ColorsV2 = {
  background: '#FFFFFF',
  secondary: '#00FFB9',
  main: '#FFFFFF',
  main2: '#FFFFFFCC',
  main3: '#E9F5FE',
  main4: '#000',
  mainNonTransparent: '#FFFFFF',
  dark: '#F9F9F9',
  dark2: '#F2F2F2',
  light: '#E0E0E0',
  lightGreen: '#050A5A',
  loadingFirst: '#D4DEE3',
  loadingSecond: '#C7D5DB',
  text: '#5A5A5A',
  textSecondary: '#8A8A8A',
  textThirdly: '#000000',
  textLink: '#0074FF',
  textDisabled: '#ADADAD',
  textInfo: '#4F8AE3',
  textWarning: '#F0AF41',
  textButton: '#FFF',
  textDanger: '#ED756E',
  success: '#56A944',
  danger: '#ED756E',
  warningBg: '#FEF8EE',
  tagBg: '#4B7EFFCC',
  tagText: '#FFFFFF',
  exploreIndicator: '#4F8AE3',
  exploreIndicatorActive: '#4F8AE3',
  saleEnds: '#000000',
  saleLive: '000000',
  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #00DAFF 0%, #00FFB9 100%)',
    green2lightblue: 'linear-gradient(90deg, #1273EA 0%, #1C94F4 100%)',
    blue2indigo: 'linear-gradient(90deg, #3580FE -2.21%, #A633FE 100%)',
    buttonNormal: 'linear-gradient(90deg, #00CB82 -2.21%, #00B4ED 100%)',
    buttonHover: 'linear-gradient(90deg, rgba(0, 203, 130, 0.5) -2.21%, rgba(0, 180, 237, 0.5) 100%)',
    buttonLoading: 'linear-gradient(90deg, rgba(0, 203, 130, 0.5) -2.21%, rgba(0, 180, 237, 0.5) 100%)',
    swapBorder: 'linear-gradient(90deg, #77acff -2.21%, #c578ff 89.35%);',
  },
  feedbacks: {
    successText: '#6EC25C',
    successBg: '#C8EFD04D',
    dangerText: '#ED756E',
    dangerBg: '#FFB2C04D',
    warningText: '#F5B452',
    warningBg: '#FEF8EE4D',
    infoText: '#4F8AE3',
    infoBg: '#DEEFFF',
    disableText: '#ADADAD',
    disableBg: '#E0E0E0',
  },
  infoCard: {
    infoText: '#4F8AE3',
    infoBg: '#DEEFFF',
  },
  button: {
    primaryBg: '#000',
    secondaryBg: '#00000080',
    tertiaryBg: '#15152E99',
    quaternaryBg: '#DEEFFF',
    quaternaryText: '#4F8AE3',
    dangerBg: '#FFB2C04D',
    disabledBg: '#E0E0E0',
    primaryText: '#FFFFFF',
    dangerText: '#ED756E',
    linkText: '#0074FF',
    disabledText: '#77777780',
    radioOptionBg: '#F2F2F2',
    radioOptionText: '#5A5A5AB2',
    radioSelectedBg: '#0AC6E5',
    radioSelectedText: '#FAFAFA',
  },
  swap: {
    background: '#FFFFFF',
    migrationBannerBackground: 'linear-gradient(90.2deg, #EFE7F3 26.53%, #E0E2F7 71.19%)',
    migrationBannerBtnColor: '#FFFFFF',
  },
  shadows: {
    settings: '0px 4px 25px 0px #0024a426',
    navbar: '0px 4px 25px 0px  rgba(151, 151, 151, 0.15)',
  },
  statusPill: {
    background: {
      open: '#4F8AE3',
      completed: '#56A944',
      closed: '#E0E0E0',
    },
    text: {
      open: '#FFFFFF',
      completed: '#FFFFFF',
      closed: '#777777',
    },
  },
  difficultyPill: {
    background: {
      beginner: '#C8EFD04D',
      intermediate: '#FEF8EE',
      advanced: '#FFB3C0',
    },
    text: {
      beginner: '#56A944',
      intermediate: '#F5B452',
      advanced: '#ED756E',
    },
  },
  roiCalculator: {
    background: '#FFFFFF',
    footerBackground: '#F5FAFF',
    footerHeaderText: '#4F8AE3',
    footerDetailsText: '#4F8AE3',
  },
}

export const blueColorsV2: ColorsV2 = {
  background: '#FFFFFF',
  secondary: '#00FFB9',
  main: '#FFFFFF',
  main2: '#FFFFFFCC',
  main3: '#E9F5FE',
  main4: '#4B7EFF',
  mainNonTransparent: '#FFFFFF',
  dark: '#F2F7FA',
  dark2: '#F2F2F2',
  light: '#E0E0E0',
  lightGreen: '#050A5A',
  loadingFirst: '#D4DEE3',
  loadingSecond: '#C7D5DB',
  text: '#5A5A5A',
  textSecondary: '#8A8A8A',
  textThirdly: '#4F8AE3',
  textLink: '#0074FF',
  textDisabled: '#ADADAD',
  textInfo: '#4F8AE3',
  textWarning: '#F0AF41',
  textButton: '#FFF',
  textDanger: '#ED756E',
  success: '#38AF1F',
  danger: '#ED756E',
  warningBg: '#FEF8EE',
  tagBg: '#4B7EFFCC',
  tagText: '#FFFFFF',
  exploreIndicator: '#4F8AE3',
  exploreIndicatorActive: '#4F8AE3',
  // hotfix, later move it to sale section
  saleEnds: '#4F8AE3',
  saleLive: '#4F8AE3',

  gradients: {
    bubblegum: 'linear-gradient(139.73deg, #00DAFF 0%, #00FFB9 100%)',
    green2lightblue: 'linear-gradient(90deg, #1273EA 0%, #1C94F4 100%)',
    blue2indigo: 'linear-gradient(90deg, #3580FE -2.21%, #A633FE 100%)',
    buttonNormal: 'linear-gradient(90deg, #00CB82 -2.21%, #00B4ED 100%)',
    buttonHover: 'linear-gradient(90deg, rgba(0, 203, 130, 0.5) -2.21%, rgba(0, 180, 237, 0.5) 100%)',
    buttonLoading: 'linear-gradient(90deg, rgba(0, 203, 130, 0.5) -2.21%, rgba(0, 180, 237, 0.5) 100%)',
    swapBorder: 'linear-gradient(90deg, #77acff -2.21%, #c578ff 89.35%);',
  },
  feedbacks: {
    successText: '#6EC25C',
    successBg: '#C8EFD04D',
    dangerText: '#ED756E',
    dangerBg: '#FFB2C04D',
    warningText: '#F5B452',
    warningBg: '#FEF8EE4D',
    infoText: '#4F8AE3',
    infoBg: '#DEEFFF',
    disableText: '#ADADAD',
    disableBg: '#E0E0E0',
  },
  infoCard: {
    infoText: '#4F8AE3',
    infoBg: '#DEEFFF',
  },
  button: {
    primaryBg: 'linear-gradient(90deg, #1273EA -2.21%, #1C94F4 100%)',
    secondaryBg: '#FFFFFF',
    tertiaryBg: '#15152E99',
    quaternaryBg: '#DEEFFF',
    quaternaryText: '#4F8AE3',
    dangerBg: '#FFB2C04D',
    disabledBg: '#E0E0E0',
    primaryText: '#FFFFFF',
    dangerText: '#ED756E',
    linkText: '#0074FF',
    disabledText: '#77777780',
    radioOptionBg: '#F2F2F2',
    radioOptionText: '#5A5A5AB2',
    radioSelectedBg: '#0AC6E5',
    radioSelectedText: '#FAFAFA',
  },
  swap: {
    background: '#FFFFFF',
    migrationBannerBackground: 'linear-gradient(90.2deg, #EFE7F3 26.53%, #E0E2F7 71.19%)',
    migrationBannerBtnColor: '#FFFFFF',
  },
  shadows: {
    settings: '0px 4px 25px 0px #0024a426',
    navbar: '0px 4px 20px 0px #003D9814',
  },
  statusPill: {
    background: {
      open: '#4F8AE3',
      completed: '#56A944',
      closed: '#E0E0E0',
    },
    text: {
      open: '#FFFFFF',
      completed: '#FFFFFF',
      closed: '#777777',
    },
  },
  difficultyPill: {
    background: {
      beginner: '#C8EFD04D',
      intermediate: '#FEF8EE',
      advanced: '#FFB3C0',
    },
    text: {
      beginner: '#56A944',
      intermediate: '#F5B452',
      advanced: '#ED756E',
    },
  },
  roiCalculator: {
    background: '#FFFFFF',
    footerBackground: '#F5FAFF',
    footerHeaderText: '#4F8AE3',
    footerDetailsText: '#4F8AE3',
  },
}
