export const links = [
  {
    label: 'News',
    items: [
      {
        label: 'Announcement',
        href: 'https://blog.impossible.finance/',
      },
    ],
  },
  process.env.REACT_APP_ENABLE_QUEST_MENU && {
    label: 'Learn',
    items: [
      {
        label: 'Impossible Quest',
        href: '/quests',
      },
    ],
  },
  {
    label: 'Developers',
    items: [
      {
        label: 'Github',
        href: 'https://github.com/ImpossibleFinance',
      },
      {
        label: 'GitBook',
        href: 'https://docs.impossible.finance/launchpad/overview',
      },
      {
        label: 'Bug Bounty',
        href: 'https://immunefi.com/bounty/impossiblefinance',
      },
    ],
  },
  {
    label: 'About',
    items: [
      {
        label: 'Analytics',
        href: 'https://info.impossible.finance/',
      },
      {
        label: 'Support',
        href: 'https://impossiblefinance.notion.site/impossiblefinance/Impossible-Finance-FAQ-f6985e354abb48649a09f67a02c04dc7',
      },
    ],
  },
]

export const roninLinks = [
  {
    label: 'News',
    items: [
      {
        label: 'Announcement',
        href: 'https://roninchain.com/blog',
      },
    ],
  },
  {
    label: 'Partnership',
    items: [
      {
        label: 'Impossible Finance',
        href: 'https://impossible.finance/',
      },
    ],
  },

  {
    label: 'About',
    items: [
      {
        label: 'Support',
        href: 'https://www.notion.so/impossiblefinance/Ronin-Launchpad-FAQ-73be73d103e44f1e81f3f1098a935b2a',
      },
    ],
  },
]

export const aethirLinks = [
  {
    label: 'News',
    items: [
      {
        label: 'Announcement',
        href: 'https://medium.com/@Aethir_',
      },
    ],
  },
  {
    label: 'About',
    items: [
      {
        label: 'Support',
        href: 'https://discord.com/invite/aethircloud',
      },
    ],
  },
]

export const MENU_HEIGHT = 66
export const MOBILE_MENU_HEIGHT = 58
export const MENU_ENTRY_HEIGHT = 48
export const SIDEBAR_WIDTH_FULL = 240
export const SIDEBAR_WIDTH_REDUCED = 56
export const ACCOUNT_SIDEBAR_WIDTH_FULL = 380
