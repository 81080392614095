// TODO: Use our own list
const PANCAKE_EXTENDED = 'https://tokens.pancakeswap.finance/pancakeswap-extended.json'
const PANCAKE_TOP100 = 'https://tokens.pancakeswap.finance/pancakeswap-top-100.json'
const SOLARBEAM_LIST = 'https://impossiblefinance.github.io/solarbeam-tokenlist/solarbeam.tokenlist.json'
const AVAX_LIST = 'https://raw.githubusercontent.com/traderjoe-xyz/joe-tokenlists/main/joe.tokenlist.json'
const AURORA_LIST = 'https://raw.githubusercontent.com/aurora-is-near/bridge-assets/master/assets/aurora.tokenlist.json'
const BOBA_LIST = 'https://impossiblefinance.github.io/boba-community-tokenlist/tokenlist.json'
const ETH_LIST = 'https://raw.githubusercontent.com/compound-finance/token-list/master/compound.tokenlist.json'

export const UNSUPPORTED_LIST_URLS: string[] = []

// lower index == higher priority for token import
export const DEFAULT_LIST_OF_LISTS: string[] = [
  ETH_LIST,
  PANCAKE_TOP100,
  PANCAKE_EXTENDED,
  SOLARBEAM_LIST,
  AVAX_LIST,
  AURORA_LIST,
  BOBA_LIST,
  ...UNSUPPORTED_LIST_URLS, // need to load unsupported tokens as well
]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [ETH_LIST]
