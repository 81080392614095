import { darkColorsV2, lightColorsV2 } from '../../theme/colorsV2'
import { ModalTheme } from './types'

export const light: ModalTheme = {
  background: lightColorsV2.main,
  boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.1);',
}

export const dark: ModalTheme = {
  background: darkColorsV2.main,
  boxShadow: '0px 4px 50px 0px rgba(0, 0, 0, 0.09);',
}
