import React from 'react'
import styled from 'styled-components'
import getExternalLinkProps from '../../util/getExternalLinkProps'
import { Button } from '../../components/Button'
import { OpenNewIcon } from '../../components/Svg'
import { ToastAction as Action } from './types'

interface ToastActionV2Props {
  action: Action
  onClick?: () => void
}

const ActionButtonContainer = styled.div`
  position: relative;
  text-align: right;
  right: -50px;
  margin-top: 22px;
  svg {
    width: 10px;
    margin-left: 8px;
    path {
      fill: ${({ theme }) => theme.colorsV2?.textLink};
    }
  }
`

const ToastActionV2: React.FC<ToastActionV2Props> = ({ action, onClick }) => {
  return (
    <ActionButtonContainer>
      <Button
        onClick={() => {
          if (onClick) {
            onClick()
          }
        }}
        as="a"
        scale="sm"
        href={action.url}
        {...getExternalLinkProps()}
        variant="text"
      >
        {action.text}
        <OpenNewIcon />
      </Button>
    </ActionButtonContainer>
  )
}

export default ToastActionV2
