import { useEffect } from 'react'

const useGetDocumentTitlePrice = () => {
  useEffect(() => {
    document.title = `Impossible Finance`

    if (process.env.REACT_APP_WHITELABEL_BRAND === 'ronin') {
      let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.head.appendChild(link)
      }
      link.href = '/faviconronin.svg'
      document.title = `Ronin`

      document.querySelector('meta[property="og:image"]').setAttribute('content', '/images/ronin-network.png')
    }

    if (process.env.REACT_APP_WHITELABEL_BRAND === 'aethir') {
      let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement
      if (!link) {
        link = document.createElement('link')
        link.rel = 'icon'
        document.head.appendChild(link)
      }
      link.href = process.env.REACT_APP_PREVIEW_FAVICO_SVG
      document.title = process.env.REACT_APP_PREVIEW_TITLE

      document.querySelector('meta[property="og:image"]').setAttribute('content', process.env.REACT_APP_PREVIEW_IMAGE)
    }
  })
}
export default useGetDocumentTitlePrice
