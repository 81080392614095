import React, { useContext, useState } from 'react'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'
import styled, { useTheme } from 'styled-components'
import IFTypography from 'componentsV2/IFTypography'
import CloseIcon from '@mui/icons-material/Close'
import { IconButton, Button, useMatchBreakpoints } from 'uikit'
import { Input } from '@mui/material'
import { PROMO_CODES } from '../consts'
import { useAccount } from 'wagmi'
import { CompanyContext } from 'contexts/CompanyContext'

const ModalBox = styled.div`
  position: absolute;
  background: ${({ theme }) => (theme.isDark ? '#2E3466' : theme.colorsV2?.main)};
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 500px;
  width: 90vw;
  border-radius: 20px;
  box-sizing: border-box;
`
const InputDiv = styled(Input)`
  background: ${({ theme }) => theme.colorsV2.dark};
  color: #fafafa;
  flex-shrink: 0;
  padding: 10px 20px;
  display: flex;
  width: 100%;
  max-width: 428px;
  opacity: 0.8;
  align-items: center;
  border: 0.5px solid ${({ theme }) => theme.colorsV2.light};
  margin-bottom: 10px;
  border-radius: 20px !important;
`

const PromoButton = styled(Button)<{ isDirty: boolean; isJustSale: boolean }>`
  color: ${({ theme, isJustSale }) => (isJustSale ? theme.colorsV2.main : '#ffffff')};
  width: 140px;
  ${({ theme }) => theme.mediaQueries?.md} {
    width: 190px;
  }
  height: 44px;
  padding: 10.5px 10px 8.5px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 21px;
  background: ${({ isDirty, theme, isJustSale }) =>
    isJustSale ? theme.colorsV2.main4 : isDirty ? theme.colorsV2.gradients.blue2indigo : theme.colorsV2.light};
`

const CancelButton = styled(Button)<{ isDirty: boolean; isJustSale: boolean }>`
  color: ${({ theme, isJustSale }) => (isJustSale ? theme.colorsV2.main4 : '#ffffff')};
  width: 140px;
  ${({ theme }) => theme.mediaQueries?.md} {
    width: 190px;
  }
  height: 44px;
  padding: 10.5px 10px 8.5px 10px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme, isJustSale }) => isJustSale && theme.colorsV2.main4};
  border-radius: 21px;
  background: ${({ theme, isJustSale }) => (isJustSale ? theme.colorsV2.main : theme.colorsV2.textDisabled)};
`

export default function ModalPromo(props) {
  const { open, setOpenModalPromo, setDiscountActive, setPromoCode: setPromoCodeParent } = props
  const theme = useTheme()
  const [promoCode, setPromoCode] = useState('')
  const { address } = useAccount()
  const [errorMessage, setErrorMessage] = useState('')
  const handleInputPromoCode = (e) => setPromoCode(e.target.value ? e.target.value : '')
  const handleClose = () => {
    setPromoCode('')
    setErrorMessage('')
    setOpenModalPromo(false)
  }
  const handleApplyPromo = () => {
    const ethereumAddressRegex = /^(0x)?[0-9a-fA-F]{40}$/
    const sameAddress = address.toLowerCase() === promoCode.toLowerCase()
    const isValidAddress = !sameAddress && ethereumAddressRegex.test(promoCode)

    if (PROMO_CODES[promoCode.toLowerCase()] || isValidAddress) {
      setDiscountActive(true)
      setPromoCodeParent(promoCode.toLowerCase())
      handleClose()
    } else {
      setErrorMessage(sameAddress ? 'Cannot use your own address.' : 'Invalid promo code.')
    }
  }
  const isDirty = promoCode.length >= 1
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false
  const { isJustSale } = useContext(CompanyContext)
  return (
    <Modal open={open} onClose={handleClose}>
      <ModalBox>
        <Box margin={isMobile ? '20px 12px 20px 16px' : '30px 30px 10px 40px'}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <IFTypography variant={isMobile ? 'h6' : 'h3'} fontWeight="bold">
              Add promo code
            </IFTypography>
            <IconButton variant="text" onClick={() => handleClose()}>
              <CloseIcon sx={{ fontSize: '34px' }} />
            </IconButton>
          </Box>
        </Box>
        <Box margin={isMobile ? '20px 12px 20px 16px' : '30px 30px 10px 40px'}>
          <IFTypography variant={'body2'} ifcolor="textSecondary">
            Referral code (wallet address format) will not be automatically applied. Rebate will only be approved and
            airdropped by XX upon confirmation referrer has purchased a node.
          </IFTypography>
        </Box>
        <Box display="flex" padding={isMobile ? '0 16px' : '0 36px'}>
          <InputDiv
            sx={{ color: theme.isDark ? '#FAFAFA' : '#5A5A5A' }}
            disableUnderline
            placeholder="Enter code"
            value={promoCode}
            onChange={handleInputPromoCode}
          />
        </Box>
        <Box style={{ visibility: errorMessage?.length > 0 ? 'visible' : 'hidden' }} ml="40px">
          <IFTypography variant="body2" ifcolor="danger">
            {errorMessage}
          </IFTypography>
        </Box>

        <Box
          display="flex"
          gap="20px"
          alignItems="center"
          justifyContent="center"
          margin={isMobile ? '10px 30px 30px 30px' : '40px 50px 30px 50px'}
        >
          <CancelButton onClick={handleClose} isJustSale={isJustSale}>
            Cancel
          </CancelButton>
          <PromoButton isDirty={isDirty} onClick={handleApplyPromo} isJustSale={isJustSale} disabled={!isDirty}>
            Apply
          </PromoButton>
        </Box>
      </ModalBox>
    </Modal>
  )
}
