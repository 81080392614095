import React from 'react'
import styled from 'styled-components'
import { Link, useHistory } from 'react-router-dom'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { Box } from '@mui/material'
import ArrowBackwardIcon from 'uikit/components/Svg/Icons/ArrowBack'
import { useMatchBreakpoints } from 'uikit'

const BackBox = styled.div`
  margin: 0;
  display: inline-flex;
  align-items: center;
  @media (max-width: 1000px) {
    padding: 0 20px;
  }
`

const BreadcrumbItemContainer = styled.div<{ isBackArrow?: boolean; $isActive?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${({ isBackArrow = false }) => (isBackArrow ? '14px 14px 14px 0' : '0 6px 0 0')};

  svg {
    width: 16px;
    height: 16px;
    fill: ${({ theme }) => theme.colorsV2?.text};
  }

  p {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-all;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    margin-left: ${({ $isActive = false }) => ($isActive ? '-8px' : '0')};
}

  ${({ theme }) => theme.mediaQueries?.md} {
    margin-right: ${({ isBackArrow = false }) => (isBackArrow ? '8px' : '16px')};
    padding: ${({ isBackArrow = false }) => (isBackArrow ? '14px' : '5px 10px ')};

    ${({ $isActive }) =>
      !$isActive &&
      `&:hover {
    border-radius: 5px;
    background: ${({ theme }) => (theme.isDark ? theme.colorsV2.main3 : theme.colorsV2?.feedbacks?.infoBg)};
    & > ${IFTypography} {
      color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.textThirdly : theme.colorsV2?.feedbacks?.infoText)};
    }
    & > svg {
      fill: ${({ theme }) => (theme.isDark ? theme.colorsV2?.textThirdly : theme.colorsV2?.feedbacks?.infoText)};
    }
  }
}`}
`
const BreadcrumbArrowItem = styled.div`
  margin-right: 10px;
  ${({ theme }) => theme.mediaQueries?.md} {
    margin-right: 16px;
  }
`

const BreadcrumbArrowSVG = styled.svg`
  fill: ${({ theme }) => (theme.isDark ? theme.colorsV2.button.disabledText : theme.colorsV2?.textDisabled)};
`

export const BreadcrumbArrow = () => (
  <BreadcrumbArrowSVG width="6" height="8" viewBox="0 0 6 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.68023 0L0.740234 0.94L3.79357 4L0.740234 7.06L1.68023 8L5.68023 4L1.68023 0Z" />
  </BreadcrumbArrowSVG>
)

export interface Crumb {
  link?: string
  title: string
}

interface BreadcrumbProps {
  breadcrumbs: Crumb[]
  hideBackButton?: boolean
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumbs, hideBackButton = false }) => {
  const history = useHistory()
  const { isXl } = useMatchBreakpoints()
  const isMobile = isXl === false

  let finalBreadcrumbs = breadcrumbs
  const showDots = isMobile && breadcrumbs.length > 2

  if (showDots) {
    finalBreadcrumbs = [finalBreadcrumbs[finalBreadcrumbs.length - 2], finalBreadcrumbs[finalBreadcrumbs.length - 1]]
  }

  return (
    <Box display="flex" alignItems="center" height="44px">
      <BackBox>
        {!hideBackButton && (
          <BreadcrumbItemContainer isBackArrow onClick={() => history.goBack()}>
            <ArrowBackwardIcon />
          </BreadcrumbItemContainer>
        )}
        {showDots && (
          <Box marginRight={2} height="16px">
            <IFTypography variant="body1">...</IFTypography>
          </Box>
        )}
        {finalBreadcrumbs.map((crumb, index) => {
          const isActive = index === finalBreadcrumbs.length - 1
          return (
            <>
              {index > 0 && (
                <BreadcrumbArrowItem>
                  <BreadcrumbArrow />
                </BreadcrumbArrowItem>
              )}
              {crumb.link && crumb.link.length > 0 ? (
                <Link to={crumb.link}>
                  <BreadcrumbItemContainer $isActive={isActive}>
                    <IFTypography
                      variant="body1"
                      fontWeight={isActive ? 'regular' : 'bold'}
                      ifcolor={isActive ? 'textSecondary' : 'text'}
                      lineHeight="24px"
                    >
                      {crumb.title}
                    </IFTypography>
                  </BreadcrumbItemContainer>
                </Link>
              ) : (
                <BreadcrumbItemContainer $isActive={isActive}>
                  <IFTypography
                    variant="body1"
                    fontWeight={isActive ? 'regular' : 'bold'}
                    ifcolor={isActive ? 'textSecondary' : 'text'}
                    lineHeight="24px"
                  >
                    {crumb.title}
                  </IFTypography>
                </BreadcrumbItemContainer>
              )}
            </>
          )
        })}
      </BackBox>
    </Box>
  )
}

export default Breadcrumb
