import tokens from 'config/constants/tokens'
import { Chain, Token } from 'config/constants/types'
import { CompanyContext } from 'contexts/CompanyContext'
import { useContext } from 'react'

interface Options {
  chainId: Chain
  showSwapInputCurrency?: boolean
}

const getMoreTokenAddress = (token: Token, options: Options): string => {
  const { isJustSale } = useContext(CompanyContext)
  const { chainId, showSwapInputCurrency = true } = options
  if (isJustSale) {
    return 'https://app.uniswap.org/swap?outputCurrency=0x82af49447d8a07e3bd95bd0d56f35241523fbab1'
  }
  if (chainId === Chain.RONIN || chainId === Chain.SAIGON) return 'https://roninchain.com/blog/posts/how-to-buy-ron'
  if (!chainId) return ''
  if (token.isLP) {
    return `/liquidity/addLP/${token.address[chainId]}`
  }
  if (token.symbol === 'vIDIA') {
    return '/vesting'
  }

  if (showSwapInputCurrency) {
    return `/swap?outputCurrency=${token.address[chainId]}&inputCurrency=${tokens.busd.address[chainId]}`
  }
  return `/swap?outputCurrency=${token.address[chainId]}`
}

export default getMoreTokenAddress
