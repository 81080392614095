import { Chain, Token } from './types'

const tokens: { [key: string]: Token } = {
  busd: {
    symbol: 'BUSD',
    address: {
      [Chain.BSC_MAINNET]: '0xe9e7CEA3DedcA5984780Bafc599bD69ADd087D56',
      [Chain.BSC_TESTNET]: '0x1df90b035a9bd57c8ab13f72ca41ad1581928b54',
      [Chain.POLYGON_MAINNET]: '0xdab529f40e671a1d4bf91361c21bf9f0c9712ab7',
      [Chain.MOONRIVER_MAINNET]: '0x5D9ab5522c64E1F6ef5e3627ECCc093f56167818',
      [Chain.MOONBEAM_MAINNET]: '0xA649325Aa7C5093d12D6F98EB4378deAe68CE23F',
      [Chain.AVAX_MAINNET]: '0x19860CCB0A68fd4213aB9D8266F7bBf05A8dDe98',
    },
    decimals: 18,
    projectLink: 'https://www.paxos.com/busd/',
  },
  if: {
    symbol: 'IF',
    address: {
      [Chain.BSC_MAINNET]: '0xb0e1fc65c1a741b4662b813eb787d369b8614af1',
      [Chain.BSC_TESTNET]: '0xf9e0a9e57b6cd690cebfcce12a8fc14a9ab134be',
      [Chain.POLYGON_MAINNET]: '0xb0e1fc65c1a741b4662b813eb787d369b8614af1',
    },
    decimals: 18,
  },
  idia: {
    symbol: 'IDIA',
    address: {
      [Chain.BSC_MAINNET]: '0x0b15ddf19d47e6a86a56148fb4afffc6929bcb89',
      [Chain.BSC_TESTNET]: '0x5C0AEF10d5820Cb616C05b954dD0378fD52ad21e',
      [Chain.POLYGON_MAINNET]: '0x0b15ddf19d47e6a86a56148fb4afffc6929bcb89',
      [Chain.MOONRIVER_MAINNET]: '0x2d28AA28fA1E5e6bF121CF688309Bf3faAAe3C70',
      [Chain.AVAX_MAINNET]: '0xfCAF13227dcBFa2dc2b1928acFcA03b85e2D25dd',
      [Chain.ETH_MAINNET]: '0xd5b87813A5ecb2eaC24b6B3F2d4b8b69F583aF4e',
      [Chain.MANTLE]: '0x08D1099101De99B14C68975222A235b63e6B8C91',
      [Chain.MOONBEAM_MAINNET]: '0xfCAF13227dcBFa2dc2b1928acFcA03b85e2D25dd',
      [Chain.ARBITRUM_MAINNET]: '0x6Db8b088c4d41d622B44CD81B900bA690f2d496C',
      [Chain.AURORA_MAINNET]: '0x2eFEDEBD6e29963511589731a33311196f00eaCb',
      [Chain.GOERLI]: '0xb3A4F81aFd78a3f9BBec4E77f08C0304c9c3fE1d',
    },
    decimals: 18,
  },
  vidia: {
    symbol: 'vIDIA',
    address: {
      [Chain.GOERLI]: '0xbf0FC24D42eA94F4F8BA8C52e1F62C6d9c096292',
      [Chain.BSC_TESTNET]: '0xC7c0A3117e1C7099eE53670ba7029a43EeF31F42',
      [Chain.BSC_MAINNET]: '0x6610f572057018843bd64adcf7c47787eb7ba8b2',
    },
    decimals: 18,
  },
  usdc: {
    symbol: 'USDC',
    address: {
      [Chain.BSC_MAINNET]: '0x8ac76a51cc950d9822d68b83fe1ad97b32cd580d',
      [Chain.MOONRIVER_MAINNET]: '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d',
      [Chain.OKC_MAINNET]: '0xc946daf81b08146b1c7a8da2a851ddf2b3eaaf85',
    },
    decimals: 18,
  },
  usdc6: {
    symbol: 'USDC',
    address: {
      [Chain.MOONRIVER_MAINNET]: '0xe3f5a90f9cb311505cd691a46596599aa1a0ad7d',
      [Chain.AVAX_MAINNET]: '0xB97EF9Ef8734C71904D8002F8b6Bc66Dd9c48a6E',
      [Chain.MOONBEAM_MAINNET]: '0x818ec0a7fe18ff94269904fced6ae3dae6d6dc0b',
      [Chain.AURORA_MAINNET]: '0xb12bfca5a55806aaf64e99521918a4bf0fc40802',
      [Chain.ETH_MAINNET]: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
    },
    decimals: 6,
  },
  usdt: {
    symbol: 'USDT',
    address: {
      [Chain.BSC_MAINNET]: '0x55d398326f99059ff775485246999027b3197955',
      [Chain.MOONRIVER_MAINNET]: '0xb44a9b6905af7c801311e8f4e76932ee959c663c',
      [Chain.OKC_MAINNET]: '0x382bb369d343125bfb2117af9c149795c6c65c50',
    },
    decimals: 18,
  },
  usdt6: {
    symbol: 'USDT',
    address: {
      [Chain.MOONBEAM_MAINNET]: '0xefaeee334f0fd1712f9a8cc375f427d9cdd40d73',
      [Chain.AVAX_MAINNET]: '0xc7198437980c041c805a1edcba50c1ce5db95118',
      [Chain.AURORA_MAINNET]: '0x4988a896b1227218e4a686fde5eabdcabd91571f',
      [Chain.ETH_MAINNET]: '0xdac17f958d2ee523a2206206994597c13d831ec7',
    },
    decimals: 18,
  },
  dai: {
    symbol: 'DAI',
    address: {
      [Chain.BSC_MAINNET]: '0x1af3f329e8be154074d8769d1ffa4ee058b1dbc3',
      [Chain.MOONRIVER_MAINNET]: '0x80a16016cc4a2e6a2caca8a4a498b1699ff0f844',
      [Chain.OKC_MAINNET]: '0x21cDE7E32a6CAF4742d00d44B07279e7596d26B9',
    },
    decimals: 18,
  },
  PAYTOKEN: {
    symbol: 'TESTPAY',
    address: {
      [Chain.BSC_MAINNET]: '',
      [Chain.BSC_TESTNET]: '0x1df90b035a9bd57c8ab13f72ca41ad1581928b54',
      [Chain.POLYGON_MAINNET]: '',
      [Chain.MOONRIVER_MAINNET]: '',
      [Chain.AVAX_MAINNET]: '', // Dummy Address
      [Chain.ETH_MAINNET]: '',
    },
    decimals: 18,
  },
  STAKINGTOKEN: {
    symbol: 'STAKING',
    address: {
      [Chain.BSC_MAINNET]: '',
      [Chain.BSC_TESTNET]: '0xf9e0a9e57b6cd690cebfcce12a8fc14a9ab134be',
      [Chain.POLYGON_MAINNET]: '',
      [Chain.MOONRIVER_MAINNET]: '',
      [Chain.AVAX_MAINNET]: '', // Dummy Address
      [Chain.ETH_MAINNET]: '',
    },
    decimals: 18,
  },
  babt: {
    symbol: 'BABT',
    address: {
      [Chain.BSC_MAINNET]: '0x2B09d47D550061f995A3b5C6F0Fd58005215D7c8',
    },
    decimals: 1,
  },

  ronin: {
    symbol: 'RON',
    address: {
      [Chain.RONIN]: '5f2db62536dd888aacea933068384318a02094c0',
      [Chain.SAIGON]: '676b1b82b30e7833f42c50ba71ab42b1e8b8cc44',
    },
    decimals: 18,
  },

  axie: {
    symbol: 'AXS',
    address: {
      [Chain.RONIN]: '97a9107c1793bc407d6f527b77e7fff4d812bece',
      [Chain.SAIGON]: '3c4e17b9056272ce1b49f6900d8cfd6171a1869d',
    },
    decimals: 18,
  },
}

export default tokens
