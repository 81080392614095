import { Box } from '@mui/material'
import DefaultIFTooltip from 'componentsV2/DefaultIFTooltip'
import { useTranslation } from 'contexts/Localization'
import { addHours, format } from 'date-fns'
import React, { ReactNode, useContext } from 'react'
import styled, { useTheme } from 'styled-components'
import { Button, CheckmarkIcon, Flex, Link, useMatchBreakpoints } from 'uikit'
import { BellIcon } from '../Icons'
import IFTypography from '../IFTypography/IFTypography'
import { CompanyContext } from 'contexts/CompanyContext'

const StepsContainer = styled.div`
  max-width: 500px;
  width: 100%;
  padding: 0 15px;
  display: inline-block;
  color: ${({ theme }) => theme.colorsV2?.textSecondary};
  span {
    text-transform: unset;
  }
  ${({ theme }) => theme.mediaQueries?.md} {
    padding: 0 20px;
  }
`
const Step = styled.div<{ isFirstStep: boolean; isJustSale }>`
  width: 100%;
  display: flex;
  border-radius: 10px;
  padding: 10px 20px;
  position: relative;
  &.active {
    background: ${({ theme, isJustSale }) =>
      isJustSale ? theme.colorsV2.dark2 : theme.isDark ? theme.colorsV2.main3 : `${theme.colorsV2.infoCard.infoBg}99`};
    color: ${({ theme }) => theme.colorsV2?.text};
    margin-bottom: 10px;
    margin-top: 10px;
    ${({ isFirstStep, theme }) =>
      !isFirstStep &&
      `
    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 2px;
      left: 25px;
      height: 10px;
      border-left: 1px solid ${theme.colorsV2?.light};
      transform: rotate(0deg);
      z-index: 1;
    }
    `}
  }
`
const StepLeft = styled.div<{ eligible?: boolean }>`
  width: 40px;
  &.active {
    .line {
      top: 22px;
      ${({ theme, eligible = false }) =>
        eligible &&
        `border-left: 1px solid ${theme.isDark ? theme.colorsV2?.textThirdly : theme.colorsV2.infoCard.infoText}`}
    }
  }
`
const StepRight = styled.div`
  width: 100%;
  height: 100%;
`
const State = styled.div<{ $isActive?: boolean; eligible?: boolean; isJustSale: boolean }>`
  position: absolute;
  top: 12px;
  left: 13px;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background: ${({ theme, $isActive = false, eligible, isJustSale }) =>
    isJustSale && $isActive && eligible
      ? theme.colorsV2.main4
      : $isActive && eligible
      ? theme.colorsV2.infoCard.infoText
      : theme.colorsV2.feedbacks.disableBg};
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  color: ${({ theme, $isActive = false, eligible = false }) =>
    $isActive && eligible
      ? theme.colorsV2.main
      : theme.isDark
      ? theme.colorsV2.button.disabledText
      : theme.colorsV2?.feedbacks.disableText};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`
const VericalLine = styled.div`
  position: absolute;
  top: 12px;
  left: 25px;
  height: 100%;
  border-left: 1px solid ${({ theme }) => theme.colorsV2?.light};
  transform: rotate(0deg);
  z-index: 1;
  &.no-border {
    border-left: none;
  }
`
const StepTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  line-height: 24px;
  letter-spacing: 0.5px;
`
const RemindButtonContainer = styled(Button)<{ isJustSale: boolean }>`
  background: ${({ theme, isJustSale }) => (isJustSale ? theme.colorsV2.main4 : theme.colorsV2?.feedbacks?.infoBg)};
  color: ${({ theme, isJustSale }) =>
    isJustSale
      ? theme.colorsV2.main
      : theme.isDark
      ? theme.colorsV2?.textSecondary
      : theme.colorsV2?.feedbacks?.infoText};
  font-size: 12px;
  font-family: 'Roboto';
  font-weight: 400;
  border-radius: 21px;
  padding: 7px 10px;
  height: 26px;
  svg {
    margin-left: 5px;
  }
`
const RemindButtonSmallContainer = styled(RemindButtonContainer)`
  padding: 0;
  border-radius: 50%;
  width: 26px;
  margin-right: 5px;
  svg {
    margin-left: 0;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;
`

export interface TimelineItem {
  title?: string
  reminderTitle?: string
  isEstimated?: boolean
  blockNumber?: number
  isAirdrop?: boolean
  isAirdropCompleted?: boolean
  formattedDateStr: string
  date?: Date
  isTBD?: boolean
  isUpcoming?: boolean
  isVesting?: boolean
  isOptedOut?: boolean
}

export interface Timeline {
  title?: string
  subtitle?: string
  tooltip?: string
  items: TimelineItem[]
  remind?: boolean
  isActive?: boolean
  component?: ReactNode

  // Used for right card
  cardComponent?: ReactNode
  isUpcoming?: boolean
  showStatePassedIcon?: boolean
  eligible?: boolean
  fathomID: string
}

const RemindButton: React.FC<{ title: string; start: Date; isSmall?: boolean; fathomID?: string }> = ({
  title,
  start,
  isSmall,
  fathomID,
}) => {
  const { t } = useTranslation()
  const startStr = format(start, "yyyyMMdd'T'kkmmss")
  const end = addHours(start, 1)
  const endStr = format(end, "yyyyMMdd'T'kkmmss")
  const calendarLink = `https://www.google.com/calendar/event?action=TEMPLATE&dates=${startStr}%2F${endStr}&text=${title.replaceAll(
    '%',
    '%25',
  )}`
  const { isJustSale } = useContext(CompanyContext)
  return (
    <StyledLink
      href={calendarLink}
      target="_blank"
      rel="noreferrer noopener"
      onClick={() => {
        if (window.fathom) {
          window.fathom.trackGoal(fathomID, 0)
        }
      }}
    >
      {isSmall ? (
        <RemindButtonSmallContainer>
          <BellIcon />
        </RemindButtonSmallContainer>
      ) : (
        <RemindButtonContainer isJustSale={isJustSale}>
          {t('Remind Me')} <BellIcon isJustSale={isJustSale} />{' '}
        </RemindButtonContainer>
      )}
    </StyledLink>
  )
}

const AirdropNotCompleted = styled.div`
  width: 14px;
  height: 14px;
  border: 1px solid ${({ theme }) => (theme.isDark ? theme.colorsV2?.light : theme.colorsV2?.feedbacks?.infoText)};
  border-radius: 50%;
`

const AirdropCompleted: React.FC<{ bgColor: string; color: string }> = ({ bgColor, color }) => {
  return (
    <Box
      bgcolor={bgColor}
      borderRadius="50%"
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="14px"
      height="14px"
    >
      <CheckmarkIcon width="8px" color={color} />
    </Box>
  )
}

type TimelineProps = {
  timelines: Timeline[]
  timezone: string
  saleTitle: string
}

const TimeLine: React.FC<TimelineProps> = ({ timelines, timezone, saleTitle }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isXl } = useMatchBreakpoints()
  const isMobile = !isXl

  const { isJustSale } = useContext(CompanyContext)
  return (
    <StepsContainer>
      <Flex mb="15px" justifyContent="space-between">
        <IFTypography variant="overline" ifcolor="textSecondary" lineHeight="18px" fontFamily="Roboto">
          {`${t('Timezone')}: ${timezone}`}
        </IFTypography>
        <Flex>
          <IFTypography variant="caption" ifcolor="text">{`${t('Timeline updates')}`}</IFTypography>
          <DefaultIFTooltip
            color={isJustSale && theme.colorsV2.main4}
            placement="top"
            leaveTouchDelay={4000}
            title="Please check the timeline periodically for any update. The distribution dates here are provided indicatively for your convenience and are subject to change."
          />
        </Flex>
      </Flex>
      {timelines.map((step, index) => {
        const {
          isActive,
          isUpcoming,
          eligible = false,
          showStatePassedIcon = false,
          title,
          tooltip,
          subtitle,
          fathomID,
        } = step
        const isPassed = !isActive && !isUpcoming
        const textColor =
          isActive && eligible ? 'text' : isUpcoming || !showStatePassedIcon ? 'textSecondary' : 'textDisabled'
        const fontWeight = isActive && eligible ? 'bold' : 'regular'
        const shouldRemind = step.isUpcoming && step.remind && step.items[0].date && !step.items[0].isTBD

        return (
          <Step key={title} className={isActive ? 'active' : ''} isFirstStep={index === 0} isJustSale={isJustSale}>
            <StepLeft className={isActive ? 'active' : ''} eligible={eligible}>
              <State $isActive={isActive} eligible={eligible} isJustSale={isJustSale}>
                {isPassed && showStatePassedIcon ? (
                  <CheckmarkIcon width="14px" color={theme.colorsV2.button.disabledText} />
                ) : (
                  index + 1
                )}
              </State>
              <VericalLine className={index === timelines.length - 1 ? 'no-border' : 'line'} />
            </StepLeft>
            <StepRight>
              <Flex justifyContent="space-between" marginBottom="5px">
                <StepTitle>
                  <IFTypography variant="body1" fontWeight="bold" lineHeight="24px" ifcolor={textColor}>
                    {title}
                  </IFTypography>
                </StepTitle>
                {shouldRemind &&
                  ((step.items[0]?.isAirdrop && step.items?.length === 1) || !step.items[0]?.isAirdrop) && (
                    <RemindButton
                      start={new Date(step.items[0].date)}
                      title={`${saleTitle} ${title} ${step.items[0].reminderTitle || step.items[0].title}`}
                      fathomID={fathomID}
                    />
                  )}
              </Flex>
              {(subtitle?.length > 0 || tooltip?.length > 0) && (
                <Flex mb="15px" alignItems="center">
                  {subtitle && (
                    <IFTypography variant="caption" fontWeight="bold" lineHeight="18px" ifcolor="text">
                      {subtitle}
                    </IFTypography>
                  )}
                  {tooltip && <DefaultIFTooltip title={tooltip} placement="top" leaveTouchDelay={isMobile && 7000} />}
                </Flex>
              )}
              {(isJustSale ? step.items.filter((item) => item.title === 'Starts') : step.items).map((item) => {
                let titleIcon = null
                const iconColor = showStatePassedIcon
                  ? theme.isDark
                    ? theme.colorsV2.button.disabledText
                    : theme.colorsV2?.textDisabled
                  : theme.colorsV2?.main
                const iconBgColor = showStatePassedIcon
                  ? theme.colorsV2?.light
                  : theme.isDark
                  ? theme.colorsV2?.textThirdly
                  : theme.colorsV2.infoCard.infoText
                if (!isUpcoming && item.isAirdropCompleted != null) {
                  titleIcon = (
                    <Box marginRight="5px">
                      {item.isAirdropCompleted ? (
                        <AirdropCompleted bgColor={iconBgColor} color={iconColor} />
                      ) : (
                        <AirdropNotCompleted />
                      )}
                    </Box>
                  )
                } else if (item.isVesting) {
                  titleIcon = (
                    <Box marginRight="5px">
                      {item.isUpcoming || item.isOptedOut ? (
                        <AirdropNotCompleted />
                      ) : (
                        <AirdropCompleted bgColor={iconBgColor} color={iconColor} />
                      )}
                    </Box>
                  )
                }
                return (
                  <>
                    <Flex justifyContent="space-between" marginBottom="5px">
                      <Box display="inline-flex" alignItems="center" flex={isMobile && '2'} mr="16px">
                        {titleIcon}
                        <IFTypography
                          variant="overline"
                          fontWeight={fontWeight}
                          fontFamily="Roboto"
                          lineHeight="18px"
                          ifcolor={item.isOptedOut ? 'textDisabled' : textColor}
                          style={{ textDecoration: item.isOptedOut && 'line-through' }}
                        >
                          {item.title}
                        </IFTypography>
                      </Box>
                      <Box display="inline-flex" alignItems="center" flex={isMobile && '3'} justifyContent="flex-end">
                        {step.remind && ((item.isAirdrop && shouldRemind) || item.isUpcoming) && !item.isOptedOut && (
                          <RemindButton
                            start={new Date(item.date)}
                            title={`${saleTitle} ${title} ${item.reminderTitle || item.title}`}
                            isSmall
                          />
                        )}
                        <IFTypography
                          variant="overline"
                          fontWeight={fontWeight}
                          fontFamily="Roboto"
                          lineHeight="18px"
                          textAlign="right"
                          ifcolor={item.isOptedOut ? 'textDisabled' : textColor}
                          style={{ textDecoration: item.isOptedOut && 'line-through' }}
                        >
                          {item.formattedDateStr}
                        </IFTypography>
                      </Box>
                    </Flex>
                    {item.blockNumber && (
                      <Flex justifyContent="space-between">
                        <IFTypography />
                        <IFTypography variant="overline" fontFamily="Roboto" ifcolor={textColor} lineHeight="18px">
                          {t('Block Number')}: {item.blockNumber}
                        </IFTypography>
                      </Flex>
                    )}
                  </>
                )
              })}
              {step.component ?? null}
            </StepRight>
          </Step>
        )
      })}
    </StepsContainer>
  )
}

export default TimeLine
