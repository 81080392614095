import { Typography } from '@mui/material'
import styled from 'styled-components'

type IFTypographyStyleProps = {
  fontWeight?: 'light' | 'regular' | 'bold'
  ifcolor?:
    | 'text'
    | 'textSecondary'
    | 'textThirdly'
    | 'textLink'
    | 'textDisabled'
    | 'textInfo'
    | 'textWarning'
    | 'warning'
    | 'main'
    | 'light'
    | 'success'
    | 'danger'
    | 'textDanger'
    | 'textButton'
  lineHeight?: string
  fontFamily?: string
  fontSize?: string
  fontStyle?: string
}

const FONT_WEIGHT = {
  light: '300',
  regular: '400',
  bold: '700',
}

const IFTypography = styled(Typography)<IFTypographyStyleProps>`
  ${({ fontWeight }) => fontWeight && `font-weight: ${FONT_WEIGHT[fontWeight]} !important`};
  color: ${({ ifcolor, theme }) => (ifcolor ? theme.colorsV2[ifcolor] : theme.colorsV2?.text)};
  ${({ lineHeight }) => lineHeight && `line-height: ${lineHeight} !important;`}
  ${({ fontFamily }) => fontFamily && `font-family: ${fontFamily} !important;`}
  ${({ fontSize }) => fontSize && `font-size: ${fontSize} !important;`}
  ${({ fontStyle }) => fontStyle && `font-style: ${fontStyle} !important;`}
`

export default IFTypography
