import React, { ReactNode } from 'react'
import { Timeline, TimelineItem } from 'componentsV2/TimeLine/TimeLine'
import {
  AuctionIDOConfig,
  PrivateIDOConfig,
  PaidStakeSaleConfig,
  FreeStakeDropConfig,
  PaidStakeDropConfig,
  SubscribeableIDO,
  PurchasableIDO,
  DroppedIDO,
  FixedAllocationIDO,
  IDO,
  CliffVestableIDO,
  LinearVestableIDO,
} from 'state/v2_types'
import format from 'date-fns/format'
import {
  isSubscribeableIDO,
  isPurchaseableIDO,
  isDroppedIDO,
  isFixedAllocationIDO,
  isCliffVestableIDO,
  isLinearVestableIDO,
} from 'state/idos/saleUtil'
import BigNumber from 'bignumber.js'
import { convertFromWei } from 'utils/formatBalance'
import { Staking, Purchasing, Claiming, Airdropping } from '../components/Card/index'
import TimeLineStakeWidget from '../components/TimeLineStakeWidget'
import CliffVestClaiming from '../components/Card/CliffVestClaiming'
import LinearVestClaiming from '../components/Card/LinearVestClaiming'

export const parseDateTime = (dateTime: string | Date): string => {
  return format(new Date(dateTime), 'yyyy-MM-dd HH:mm')
}

export const parseDate = (dateTime: string | Date): string => {
  return format(new Date(dateTime), 'yyyy-MM-dd')
}

const airdropPercentages = (firstDayRelease?: string): [string, string] => {
  const firstPercetage = parseFloat(firstDayRelease)
  if (Number.isNaN(firstPercetage)) {
    return ['', '']
  }
  const finalPercentage = 100 - firstPercetage
  return [firstDayRelease, `${finalPercentage}%`]
}

export const getCardBasedOnTimeline = (timelines: Timeline[]): ReactNode => {
  if (timelines.length === 0) {
    return null
  }
  const currentTimeline = timelines.find((timeline) => timeline.isActive || timeline.isUpcoming)
  if (currentTimeline) {
    return currentTimeline.cardComponent
  }
  // If none is active or upcoming, return the last timeline to render completed
  return timelines[timelines.length - 1].cardComponent
}

type GetTimelinesParams = {
  sale: PaidStakeSaleConfig | PaidStakeDropConfig | FreeStakeDropConfig | PrivateIDOConfig | AuctionIDOConfig | IDO
  saleTokenImage: string
  userData: any
  participable: boolean
  translation: (key: string) => string
  isQuickSidebar?: boolean
}

const isIneligibleToPurchase = (sale: IDO, participable: boolean, isStaked: boolean) => {
  const subscribeableSale = sale as SubscribeableIDO
  const purchasableSale = sale as PurchasableIDO

  if (!participable) return true
  if (!isPurchaseableIDO(purchasableSale)) return true
  const now = new Date()

  if (isSubscribeableIDO(subscribeableSale)) {
    return now > new Date(subscribeableSale.subscribePeriod.endTime) && !isStaked
  }
  return false
}

const isIneligibleToClaimOrAirdrop = (
  sale: IDO,
  participable: boolean,
  isPurchased: boolean,
  isStaked: boolean,
): boolean => {
  const purchasableSale = sale as PurchasableIDO
  const subscribeableSale = sale as SubscribeableIDO
  const now = new Date()

  if (!participable) return true

  let result = false

  if (isSubscribeableIDO(subscribeableSale)) {
    result = result || (now > new Date(subscribeableSale.subscribePeriod.endTime) && !isStaked)
  }

  if (isPurchaseableIDO(purchasableSale)) {
    result = result || (now > new Date(purchasableSale.purchasePeriod.endTime) && !isPurchased)
  }

  return result
}

const getTimelines = ({
  participable,
  sale,
  saleTokenImage,
  translation,
  userData,
  isQuickSidebar,
}: GetTimelinesParams): Timeline[] => {
  const now = new Date()
  const timelines: Timeline[] = []
  const { paymentToken } = sale as PurchasableIDO

  const paymentReceived = new BigNumber(
    convertFromWei(userData?.paymentReceivedInWei ?? 0, paymentToken?.decimals || 18),
  )

  const isPurchased = paymentReceived.isGreaterThan(0)
  const userStakeWeight = new BigNumber(userData?.userStakeWeight ?? 0)
  const isStaked = userStakeWeight.isGreaterThan(0)
  const ineligibleToClaimOrAirdrop = isIneligibleToClaimOrAirdrop(sale, participable, isPurchased, isStaked)
  let stakingPeriodEnd = false
  let purchasePeriodEnd = false
  if (isSubscribeableIDO(sale as SubscribeableIDO)) {
    const {
      subscribePeriod: { startTime, endTime, allocSnapshotBlock },
    } = sale as SubscribeableIDO
    if (now > new Date(endTime)) {
      stakingPeriodEnd = true
    }
    timelines.push({
      title: translation('Staking'),
      remind: participable,
      isActive: now > new Date(startTime) && now < new Date(endTime),
      isUpcoming: now < new Date(startTime),
      cardComponent: <Staking sale={sale as SubscribeableIDO} />,
      showStatePassedIcon: participable && isStaked,
      eligible: participable,
      fathomID: 'ZP5KPAEF',
      items: [
        {
          title: translation('Starts'),
          formattedDateStr: parseDateTime(startTime),
          date: new Date(startTime),
        },
        {
          title: translation('Ends (Estimated)'),
          formattedDateStr: parseDateTime(endTime),
          date: new Date(endTime),
          blockNumber: !!allocSnapshotBlock && allocSnapshotBlock,
        },
      ],
      component:
        participable && !isQuickSidebar ? (
          <TimeLineStakeWidget sale={sale as SubscribeableIDO} saleTokenImage={saleTokenImage} />
        ) : null,
    })
  }

  if (isPurchaseableIDO(sale as PurchasableIDO)) {
    const {
      purchasePeriod: { startTime, endTime },
    } = sale as PurchasableIDO
    if (now > new Date(endTime)) {
      purchasePeriodEnd = true
    }
    const ineligibleToPurchase = isIneligibleToPurchase(sale, participable, isStaked)
    timelines.push({
      title: translation('Purchase'),
      remind: !ineligibleToPurchase,
      isActive:
        (now > new Date(startTime) && now < new Date(endTime)) ||
        (ineligibleToPurchase && now < new Date(startTime) && stakingPeriodEnd),
      isUpcoming: now < new Date(startTime),
      cardComponent: <Purchasing sale={sale as PurchasableIDO} />,
      showStatePassedIcon: !ineligibleToPurchase && isPurchased,
      eligible: !ineligibleToPurchase,
      fathomID: 'FJV9AUB6',
      items: [
        {
          title: translation('Starts'),
          formattedDateStr: parseDateTime(startTime),
          date: new Date(startTime),
        },
        {
          title: translation('Ends'),
          formattedDateStr: parseDateTime(endTime),
          date: new Date(endTime),
        },
      ],
    })
  }

  if ('claimPeriod' in sale && sale.claimPeriod && sale.claimPeriod.startTime) {
    const hasWithdrawn = userData?.hasWithdrawn || false
    const {
      claimPeriod: { startTime },
    } = sale
    timelines.push({
      title: translation('Claim'),
      remind: !ineligibleToClaimOrAirdrop,
      isActive: !hasWithdrawn && now > new Date(startTime) && !ineligibleToClaimOrAirdrop, // TODO: Show inactive if user has claimed
      isUpcoming: now < new Date(startTime),
      cardComponent: <Claiming sale={sale} />,
      showStatePassedIcon: !ineligibleToClaimOrAirdrop && hasWithdrawn,
      eligible: !ineligibleToClaimOrAirdrop,
      fathomID: '2GG6GNZ1',
      items: [
        {
          title: translation('Starts'),
          formattedDateStr: `${translation('Estimated')}: ${parseDateTime(startTime)}`,
          date: new Date(startTime),
        },
      ],
    })
  }

  if (isCliffVestableIDO(sale as CliffVestableIDO)) {
    const { vestInfoLabel, vestInfoTooltip, vestList } = (sale as CliffVestableIDO).cliffVestInfo
    let allocated = new BigNumber(0)
    let purchased = new BigNumber(0)
    let claimableNumber = 0

    if (isSubscribeableIDO(sale as SubscribeableIDO)) {
      allocated = userData?.userTokenAllocation || new BigNumber(0)
    } else if (isFixedAllocationIDO(sale as FixedAllocationIDO)) {
      allocated = new BigNumber((sale as FixedAllocationIDO).saleTokenAllocationOverride)
    }

    if (isPurchaseableIDO(sale as PurchasableIDO)) {
      const {
        purchasePeriod: { salePrice, buybackClaimableNumber },
      } = sale as PurchasableIDO
      purchased = paymentReceived.div(salePrice)
      claimableNumber = buybackClaimableNumber
    } else {
      purchased = allocated
    }
    const allocation = purchased.isFinite() ? purchased : new BigNumber(0)
    const hasAllocation = allocation.isGreaterThan(0)
    const claimsData: TimelineItem[] = vestList?.map((vestItem, index) => {
      return {
        title: vestItem.title,
        reminderTitle: vestItem.title,
        formattedDateStr: parseDateTime(vestItem.date),
        date: new Date(vestItem.date),
        isUpcoming: now < new Date(vestItem.date),
        isVesting: true,
        isOptedOut: index >= claimableNumber && userData?.userHasOptedIn,
      }
    })

    timelines.push({
      title: translation('Claim'),
      subtitle: vestInfoLabel,
      tooltip: vestInfoTooltip,
      remind: !ineligibleToClaimOrAirdrop,
      isActive: now > new Date(vestList?.[0]?.date) && !ineligibleToClaimOrAirdrop,
      isUpcoming: vestList?.[0]?.date && now < new Date(vestList?.[0]?.date),
      eligible: !ineligibleToClaimOrAirdrop,
      cardComponent: <CliffVestClaiming sale={sale as CliffVestableIDO} />,
      fathomID: 'YK6EIQSZ',
      showStatePassedIcon:
        !ineligibleToClaimOrAirdrop &&
        hasAllocation &&
        vestList?.[vestList?.length - 1]?.date &&
        now > new Date(vestList?.[vestList?.length - 1]?.date),
      items: claimsData,
    })
  }

  if (isLinearVestableIDO(sale as LinearVestableIDO)) {
    const { linearVestInfo } = sale as LinearVestableIDO
    let allocated = new BigNumber(0)
    let purchased = new BigNumber(0)

    if (isSubscribeableIDO(sale as SubscribeableIDO)) {
      allocated = userData?.userTokenAllocation || new BigNumber(0)
    } else if (isFixedAllocationIDO(sale as FixedAllocationIDO)) {
      allocated = new BigNumber((sale as FixedAllocationIDO).saleTokenAllocationOverride)
    }

    if (isPurchaseableIDO(sale as PurchasableIDO)) {
      const {
        purchasePeriod: { salePrice },
      } = sale as PurchasableIDO
      purchased = paymentReceived.div(salePrice)
    } else {
      purchased = allocated
    }

    const allocation = purchased.isFinite() ? purchased : new BigNumber(0)
    const hasAllocation = allocation.isGreaterThan(0)

    timelines.push({
      title: translation('Claim'),
      remind: !ineligibleToClaimOrAirdrop,
      isActive:
        now > new Date(linearVestInfo.startTime) &&
        !ineligibleToClaimOrAirdrop &&
        now < new Date(linearVestInfo.endTime),
      isUpcoming: now < new Date(linearVestInfo.startTime),
      eligible: !ineligibleToClaimOrAirdrop,
      fathomID: 'RSLAGJYB',
      cardComponent: <LinearVestClaiming sale={sale as LinearVestableIDO} />,
      showStatePassedIcon: !ineligibleToClaimOrAirdrop && hasAllocation && now > new Date(linearVestInfo.endTime),
      items: [
        {
          title: translation('Starts'),
          formattedDateStr: parseDateTime(linearVestInfo.startTime),
          date: new Date(linearVestInfo.startTime),
        },
        {
          title: translation('Ends'),
          formattedDateStr: parseDateTime(linearVestInfo.endTime),
          date: new Date(linearVestInfo.endTime),
        },
      ],
    })
  }

  if (isDroppedIDO(sale as DroppedIDO)) {
    const { airdropInfo, token } = sale as DroppedIDO
    const { finalAirdrop, firstDayRelease, initialAirdrop, isFinalTBD, isInitialTBD, details } = airdropInfo
    // Need to update this for allocatedAirdrop in the future
    let allocated = new BigNumber(0)
    let purchased = new BigNumber(0)
    let showActiveForIneligiblableUser =
      ineligibleToClaimOrAirdrop && (isInitialTBD || (initialAirdrop && now < new Date(initialAirdrop)))

    if (isSubscribeableIDO(sale as SubscribeableIDO)) {
      allocated = userData?.userTokenAllocation || new BigNumber(0)
      showActiveForIneligiblableUser = showActiveForIneligiblableUser && stakingPeriodEnd
    } else if (isFixedAllocationIDO(sale as FixedAllocationIDO)) {
      allocated = new BigNumber((sale as FixedAllocationIDO).saleTokenAllocationOverride)
    }

    if (isPurchaseableIDO(sale as PurchasableIDO)) {
      const {
        purchasePeriod: { salePrice },
      } = sale as PurchasableIDO
      purchased = paymentReceived.div(salePrice)
      showActiveForIneligiblableUser = showActiveForIneligiblableUser && purchasePeriodEnd
    } else {
      purchased = allocated
    }
    const airdropAllocation = purchased.isFinite() ? purchased : new BigNumber(0)
    const percentages = airdropPercentages(firstDayRelease)
    const hasAllocation = airdropAllocation.isGreaterThan(0)
    const airdropData = []
    if (details?.length > 0) {
      for (let i = 0; i < details.length; i++) {
        const detail = details[i]

        if (token.symbol === 'dAethirNode') {
          airdropData.push({
            title: 'Airdrop',
            reminderTitle: 'Airdrop',
            formattedDateStr: 'TBD',
            date: null,
            isTBD: true,
            isUpcoming: true,
            isAirdrop: true,
            isAirdropCompleted: null,
          })
        } else {
          airdropData.push({
            title: token.symbol === 'APRS' ? 'Claim in Apeiron' : detail.name,
            reminderTitle: token.symbol === 'APRS' ? 'Claim' : 'Airdrop',
            formattedDateStr: parseDateTime(detail.date),
            date: new Date(detail.date),
            isTBD: false,
            isUpcoming: now < new Date(detail.date),
            isAirdrop: true,
            isAirdropCompleted: !ineligibleToClaimOrAirdrop && hasAllocation ? now > new Date(detail.date) : null,
          })
        }
      }
    } else {
      airdropData.push({
        title: finalAirdrop
          ? `${translation('1st Airdrop')} - ${percentages[0].toString()}`
          : token.symbol === 'ARKEN'
          ? "Claim in Arken's site"
          : translation('Airdrop'),
        reminderTitle: finalAirdrop
          ? `${translation('1st Airdrop')} - ${encodeURIComponent(percentages[0].toString())}`
          : translation('Airdrop'),
        formattedDateStr: isInitialTBD ? 'TBD' : parseDateTime(initialAirdrop),
        date: new Date(initialAirdrop),
        isTBD: isInitialTBD,
        isAirdrop: true,
        isAirdropCompleted:
          !ineligibleToClaimOrAirdrop && hasAllocation ? initialAirdrop && now > new Date(initialAirdrop) : null,
      })

      if (finalAirdrop) {
        airdropData.push({
          title: `${translation('2nd Airdrop')} - ${percentages[1].toString()}`,
          reminderTitle: `${translation('2nd Airdrop')} - ${encodeURIComponent(percentages[1].toString())}`,
          formattedDateStr: isFinalTBD ? 'TBD' : parseDateTime(finalAirdrop),
          date: new Date(finalAirdrop),
          isTBD: isFinalTBD,
          isAirdrop: true,
          isAirdropCompleted: !ineligibleToClaimOrAirdrop && hasAllocation ? now > new Date(finalAirdrop) : null,
        })
      }
    }

    if (sale.isSeparateClaim) {
      const vestStartDate = new Date('2024-01-22')
      const vestEndDate = new Date('2024-10-22')

      airdropData.push({
        title: `Vesting Starts`,
        reminderTitle: `Vesting Starts`,
        formattedDateStr: parseDate(new Date(vestStartDate)),
        date: new Date(vestStartDate),
        isAirdrop: true,
        isAirdropCompleted: !ineligibleToClaimOrAirdrop && hasAllocation ? now > new Date(vestStartDate) : null,
      })

      airdropData.push({
        title: `Vesting Ends`,
        reminderTitle: `Vesting Ends`,
        formattedDateStr: parseDate(new Date(vestEndDate)),
        date: new Date(vestEndDate),
        isAirdrop: true,
        isAirdropCompleted: !ineligibleToClaimOrAirdrop && hasAllocation ? now > new Date(vestEndDate) : null,
      })
    }

    timelines.push({
      title: sale.isSeparateClaim
        ? 'Distribution'
        : token.symbol === 'ARKEN'
        ? "Claim in Arken's site"
        : translation('Airdrop'),
      remind: !ineligibleToClaimOrAirdrop,
      isUpcoming: isInitialTBD || (initialAirdrop && now < new Date(initialAirdrop)),
      eligible: !ineligibleToClaimOrAirdrop,
      fathomID: 'K3EYZ8BQ',
      showStatePassedIcon:
        !ineligibleToClaimOrAirdrop && hasAllocation && now > new Date(finalAirdrop || initialAirdrop),
      cardComponent: (
        <Airdropping
          sale={sale as DroppedIDO}
          airdropInfo={airdropInfo}
          token={token}
          estimatedAllocation={airdropAllocation}
        />
      ),
      isActive:
        showActiveForIneligiblableUser ||
        sale.isSeparateClaim ||
        (finalAirdrop ? initialAirdrop && now > new Date(initialAirdrop) && now < new Date(finalAirdrop) : false),
      items: airdropData,
    })
  }

  return timelines
}

export default getTimelines
