import React, { useEffect } from 'react'
import styled from 'styled-components'
import useTheme from 'hooks/useTheme'
import { Box } from '@mui/material'
import MenuClose from '@mui/icons-material/Close'
import { useTranslation } from 'contexts/Localization'
import { Flex, Toggle } from 'uikit'
import { RU, EN, FR } from 'config/localization/languages'
import useStagingIDO from 'hooks/useStagingIDO'
import ThemeSwitcher from './ThemeSwitcher'
import { MobileLangSelector } from './LangSelector'
import { links } from '../config'

const MobileSelectorRoot = styled.div`
  border-radius: 20px 20px 0px 0px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  font-family: Roboto;
  background: ${({ theme }) => theme.colorsV2?.main};
  color: ${({ theme }) => theme.colorsV2?.text};
  padding: 20px 20px 40px 20px;
  display: flex;
  flex-direction: column;
  z-index: ${({ theme }) => theme.zIndices.modal};
  filter: ${({ theme }) =>
    `drop-shadow(0px -4px 12px ${theme.isDark ? 'rgba(3, 0, 37, 0.38)' : 'rgba(3, 0, 37, 0.1)'})`};
`

const TextPrimary = styled.p`
  font-size: 14px;
  line-height: 28px;
`
const TextTitle = styled.p`
  font-size: 12px;
  margin-bottom: 10px;
  color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.textThirdly : theme.colorsV2?.textSecondary)};
`

const ExternalLinkWrap = styled.a`
  font-size: 16px;
  line-height: 30px;
  text-decoration: none;
  width: 100%;
`

const Break = styled.div`
  height: 0.5px;
  background-color: ${({ theme }) => theme.nav.dropdownBorder};
  margin: 10px 0 20px 0;
`

const languages = [EN, RU, FR]

interface Props {
  dismissHandlerWrapper: React.MutableRefObject<() => void>
}

const MobileSettings: React.FC<Props> = ({ dismissHandlerWrapper }) => {
  const { isDark, toggleTheme } = useTheme()
  const { currentLanguage, setLanguage } = useTranslation()
  const { isStagingIDO, toggleStagingIDO } = useStagingIDO()
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0]
    body.style.overflow = 'hidden'
    return () => {
      body.style.removeProperty('overflow')
    }
  }, [])
  return (
    <MobileSelectorRoot>
      <Box display="flex" justifyContent="space-between" width="100%" paddingRight="10px" marginBottom={3}>
        <Box fontSize="18px" fontWeight="700" lineHeight="24px" fontFamily="Acre">
          More
        </Box>
        <Box onClick={() => dismissHandlerWrapper.current()}>
          <MenuClose />
        </Box>
      </Box>
      {process.env.REACT_APP_ENABLE_STAGING_TOGGLE && (
        <Flex justifyContent="space-between" alignItems="center" marginBottom="10px">
          <TextPrimary>Use staging IDO</TextPrimary>
          <Toggle checked={isStagingIDO} scale="md" onChange={toggleStagingIDO} />
        </Flex>
      )}
      {process.env.REACT_APP_ENABLE_LIGHT_DARK && (
        <Flex justifyContent="space-between" alignItems="center" marginBottom="10px">
          <TextPrimary>{isDark ? 'Dark' : 'Light'} Mode</TextPrimary>
          <ThemeSwitcher isDark={isDark} toggleTheme={toggleTheme} />
        </Flex>
      )}

      {process.env.REACT_APP_ENABLE_LANGUAGE && (
        <Flex justifyContent="space-between" alignItems="center">
          <TextPrimary>Language</TextPrimary>
          <MobileLangSelector setLang={setLanguage} currentLang={currentLanguage} langs={languages} />
        </Flex>
      )}
      {links
        .filter((item) => item?.label?.length > 0)
        .map(({ label, items }) => {
          return (
            <>
              <Break />
              <TextTitle>{label}</TextTitle>
              {items.map((item) => {
                return (
                  <Flex justifyContent="space-between" alignItems="center" marginBottom="5px">
                    <ExternalLinkWrap href={item.href} target="_blank">
                      {item.label}
                    </ExternalLinkWrap>
                  </Flex>
                )
              })}
            </>
          )
        })}
    </MobileSelectorRoot>
  )
}

export default React.memo(MobileSettings, () => true)
