import { Box } from '@mui/material'
import BigNumber from 'bignumber.js'
import Countdown from 'componentsV2/Countdown/Countdown'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { useTranslation } from 'contexts/Localization'
import React, { useContext, useMemo } from 'react'
import { useSaleUserData } from 'state/idos/hooks'
import {
  isClaimableIDO,
  isDroppedIDO,
  isFixedAllocationIDO,
  isPurchaseableIDO,
  isSubscribeableIDO,
} from 'state/idos/saleUtil'
import { isSaleParticipated } from 'state/idos/utils'
import { ClaimableIDO, DroppedIDO, FixedAllocationIDO, IDO, PurchasableIDO, SubscribeableIDO } from 'state/v2_types'
import styled, { useTheme } from 'styled-components'
import { convertFromWei } from 'utils/formatBalance'
import { formatAmount } from './helper'
import { Button } from 'uikit'
import { CompanyContext } from 'contexts/CompanyContext'

interface HeaderProps {
  sale: IDO
  isMobile?: boolean
}

const Container = styled.div<{ isMobile: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: ${({ isMobile }) => (isMobile ? '20px' : '30px')};
  //align-items: ${({ isMobile }) => (isMobile ? 'start' : 'normal')};

  & > div {
    display: flex;
    justify-content: space-between;

    & > span {
      padding-right: ${({ isMobile }) => (isMobile ? '15px' : '0px')};
    }

    &:not(:last-child) {
      margin-bottom: 5px;
    }

    &:not(:first-child) {
      height: 20px;
    }
  }
`
const SaleEndTypo = styled.div`
  color: ${({ theme }) => (theme.isDark ? theme.colorsV2.text : theme.colorsV2.textDisabled)};
  font-family: Roboto;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
`
const StrokeSale = styled.div`
  width: 20px;
  height: 1px;
  background-color: ${({ theme }) => theme.colorsV2.saleEnds};
`
const ActionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6px;
`
const ButtonAction = styled(Button)`
  background: none;
  display: flex;
  max-width: 280px;
  width: 100%;

  height: 38px;
  padding: 10px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: ${({ theme }) => theme.colorsV2.textThirdly};
  border: ${({ theme }) => `1px solid ${theme.colorsV2.textThirdly}`};
  border-radius: 21px;

  @media (max-width: 300px) {
    padding: 10px;
  }
`

const SubscriptionNotStart: React.FC<{ sale: SubscribeableIDO }> = ({ sale }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const stakingStartTimeInSeconds = Math.trunc(
    (new Date(sale.subscribePeriod.startTime).getTime() - new Date().getTime()) / 1000,
  )
  const [descInactiveStyle, countdownInactiveStyle] = useMemo(() => {
    return [
      {
        color: theme.colorsV2?.textDisabled,
        fontSize: '12px',
        lineHeight: '18px',
        alignSelf: 'center',
      },
      {
        color: theme.colorsV2?.textDisabled,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
      },
    ]
  }, [theme])

  return (
    <ActionContainer>
      <ButtonAction
        style={{
          color: theme.colorsV2?.textDisabled,
          border: `1px solid ${theme.colorsV2.light}`,
        }}
      >
        {t('Stake')}
      </ButtonAction>
      <Countdown
        seconds={stakingStartTimeInSeconds}
        description={t('Starts in')}
        descriptionStyle={descInactiveStyle}
        style={countdownInactiveStyle}
        descriptionPosition="LEFT"
      />
    </ActionContainer>
  )
}

const PurchaseNotStart: React.FC<{ sale: PurchasableIDO }> = ({ sale }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isJustSale } = useContext(CompanyContext)

  const stakingStartTimeInSeconds = Math.trunc(
    (new Date(sale.purchasePeriod.startTime).getTime() - new Date().getTime()) / 1000,
  )
  const [descInactiveStyle, countdownInactiveStyle] = useMemo(() => {
    return [
      {
        color: theme.colorsV2?.textDisabled,
        fontSize: '12px',
        lineHeight: '18px',
        alignSelf: 'center',
      },
      {
        color: theme.colorsV2?.textDisabled,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
      },
    ]
  }, [theme])

  return (
    <ActionContainer>
      <ButtonAction
        style={{
          color: theme.colorsV2?.textDisabled,
          border: `1px solid ${theme.colorsV2.light}`,
        }}
      >
        {t('Purchase')}
      </ButtonAction>
      {!isJustSale && (
        <Countdown
          seconds={stakingStartTimeInSeconds}
          description={t('Starts in')}
          descriptionStyle={descInactiveStyle}
          style={countdownInactiveStyle}
          descriptionPosition="LEFT"
        />
      )}
    </ActionContainer>
  )
}

const SubscriptionStartNotEnd: React.FC<{ sale: SubscribeableIDO }> = ({ sale }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const {
    subscribePeriod: { endTime },
  } = sale

  const stakingEndTimeInSeconds = Math.trunc((new Date(endTime).getTime() - new Date().getTime()) / 1000)

  const [descActiveStyle, countdownActiveStyle] = useMemo(() => {
    return [
      {
        color: theme.colorsV2?.saleLive,
        fontSize: '12px',
        lineHeight: '18px',
        alignSelf: 'center',
      },
      {
        color: theme.colorsV2?.saleLive,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
      },
    ]
  }, [theme])
  return (
    <ActionContainer>
      <ButtonAction style={{ color: theme.colorsV2.textThirdly }}>{t('Stake')}</ButtonAction>
      <Countdown
        seconds={stakingEndTimeInSeconds}
        description={t('Ends in')}
        descriptionStyle={descActiveStyle}
        style={countdownActiveStyle}
        descriptionPosition="LEFT"
      />
    </ActionContainer>
  )
}

const PurchaseNotEnd: React.FC<{ sale: PurchasableIDO }> = ({ sale }) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { isJustSale } = useContext(CompanyContext)

  const {
    purchasePeriod: { startTime, endTime, salePrice, maxTotalPurchasable },
    token: { symbol: idoTokenSymbol, decimals: tokenDecimals },
    id,
    paymentToken,
    userAllocation: userAllocationInWei,
    saleTokenDefaultName,
  } = sale
  const userData = useSaleUserData(id)
  const paymentReceivedInWei = userData?.paymentReceivedInWei
  const paymentReceived = convertFromWei(paymentReceivedInWei || new BigNumber(0))
  const purchasedAmount = paymentReceived.dividedBy(salePrice)
  const purchaseStartTimeInSeconds = Math.trunc((new Date(startTime).getTime() - new Date().getTime()) / 1000)
  const purchaseEndTimeInSeconds = Math.trunc((new Date(endTime).getTime() - new Date().getTime()) / 1000)
  const purchaseStarted = purchaseStartTimeInSeconds < 0
  const isSubscribeable = isSubscribeableIDO(sale as IDO as SubscribeableIDO)
  const isFixedAllocation = isFixedAllocationIDO(sale as IDO as FixedAllocationIDO)
  const hasPurchased = paymentReceived.isGreaterThan(0)

  const userAllocation = userAllocationInWei && convertFromWei(new BigNumber(userAllocationInWei), tokenDecimals)
  const estimatedAllocation = userAllocation || userData?.userTokenAllocation || new BigNumber(0)

  const paymentReceivedInPaymentToken = convertFromWei(paymentReceivedInWei, paymentToken.decimals)
  const purchasedAllocation = paymentReceivedInPaymentToken.dividedBy(salePrice)

  const participated = isSaleParticipated(sale, userData)
  const isPurchasedAll =
    new BigNumber(maxTotalPurchasable).isEqualTo(purchasedAllocation) && !purchasedAllocation.isZero()
  const [descInactiveStyle, countdownInactiveStyle, descActiveStyle, countdownActiveStyle] = useMemo(() => {
    return [
      {
        color: theme.colorsV2?.textDisabled,
        fontSize: '12px',
        lineHeight: '18px',
        alignSelf: 'center',
      },
      {
        color: theme.colorsV2?.textDisabled,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
      },
      {
        color: theme.colorsV2?.textThirdly,
        fontSize: '12px',
        lineHeight: '18px',
        alignSelf: 'center',
      },
      {
        color: theme.colorsV2?.textThirdly,
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '20px',
      },
    ]
  }, [theme])
  return (
    <>
      <ActionContainer>
        <ButtonAction
          style={{
            color: isPurchasedAll ? theme.colorsV2?.textDisabled : theme.colorsV2?.saleLive,
            border: `1px solid ${isPurchasedAll ? theme.colorsV2?.textDisabled : theme.colorsV2?.saleLive}`,
          }}
        >
          {isPurchasedAll ? t('Purchased All') : t('Purchase')}
        </ButtonAction>
        {!isJustSale && (
          <Countdown
            seconds={purchaseStarted ? purchaseEndTimeInSeconds : purchaseStartTimeInSeconds}
            description={purchaseStarted ? t('Ends in') : t('Starts in')}
            descriptionStyle={
              isPurchasedAll
                ? countdownInactiveStyle
                : isSubscribeable || purchaseStarted
                ? countdownActiveStyle
                : descInactiveStyle
            }
            style={
              isPurchasedAll
                ? countdownInactiveStyle
                : isSubscribeable || purchaseStarted
                ? countdownActiveStyle
                : countdownInactiveStyle
            }
            descriptionPosition="LEFT"
          />
        )}
      </ActionContainer>
      {isSubscribeable ||
        (isFixedAllocation && (
          <Box marginBottom="25px">
            <IFTypography variant="caption" ifcolor="text" fontWeight="bold">
              {t(hasPurchased ? 'Total Purchased' : 'Total Allocation')}
            </IFTypography>
            <IFTypography variant="button" ifcolor="text" fontWeight="bold" fontFamily="Roboto">
              {formatAmount(
                hasPurchased ? purchasedAmount : estimatedAllocation,
                idoTokenSymbol || saleTokenDefaultName,
              )}
            </IFTypography>
          </Box>
        ))}
    </>
  )
}

const Closed: React.FC<{ sale: IDO; isMobile?: boolean }> = ({ sale, isMobile = false }) => {
  const { t } = useTranslation()
  const {
    id,
    isEnd: isAethirEnd,
    token: { symbol: idoTokenSymbol, decimals },
    saleTokenDefaultName,
    userAllocation: userAllocationInWei,
  } = sale
  const userData = useSaleUserData(id)
  const paymentReceivedInWei = userData?.paymentReceivedInWei
  const paymentReceived = convertFromWei(paymentReceivedInWei || new BigNumber(0))
  const isPurchaseable = isPurchaseableIDO(sale as PurchasableIDO)
  const userAllocation = userAllocationInWei && convertFromWei(new BigNumber(userAllocationInWei), decimals)
  const estimatedAllocation = userAllocation || userData?.userTokenAllocation || new BigNumber(0)

  let purchasedAmount = new BigNumber(0)
  let airdroppedAmount = new BigNumber(0)
  let isAirdropped = false

  const hasPurchased = new BigNumber(paymentReceivedInWei ?? 0).isGreaterThan(0)
  const hasWithdrawn = userData?.hasWithdrawn ?? false

  const theme = useTheme()
  if (isPurchaseable) {
    purchasedAmount = paymentReceived.dividedBy((sale as PurchasableIDO).purchasePeriod.salePrice)
  } else {
    purchasedAmount = estimatedAllocation
  }
  if (isClaimableIDO(sale as ClaimableIDO)) {
    const now = new Date()
    const { claimPeriod } = sale as ClaimableIDO

    const { startTime } = claimPeriod
    if (now > new Date(startTime) && hasPurchased && !hasWithdrawn)
      return (
        <ActionContainer>
          <ButtonAction
            style={{
              color: theme.colorsV2?.textThirdly,
            }}
          >
            {t('Claim')}
          </ButtonAction>
        </ActionContainer>
      )
  }
  if (isDroppedIDO(sale as DroppedIDO)) {
    const now = new Date()
    const {
      airdropInfo: { finalAirdrop, firstDayRelease, initialAirdrop },
    } = sale as DroppedIDO

    if (finalAirdrop && now >= new Date(finalAirdrop)) {
      airdroppedAmount = isPurchaseable ? purchasedAmount : estimatedAllocation
      isAirdropped = true
    } else if (initialAirdrop && now >= new Date(initialAirdrop)) {
      airdroppedAmount = (isPurchaseable ? purchasedAmount : estimatedAllocation)
        .multipliedBy(parseFloat(firstDayRelease))
        .div(100)
      isAirdropped = true
    }
  }

  let closedPeriodText = null
  if (isPurchaseable && !hasPurchased) {
    if (parseInt(estimatedAllocation) > 0) {
      closedPeriodText = (
        <IFTypography variant="caption" ifcolor="text" fontWeight="bold" textAlign="left">
          {t('Incompleted Purchase')}
        </IFTypography>
      )
    }
  } else {
    closedPeriodText = (
      <Box marginBottom={isMobile ? '0px' : '25px'} width="100%" justifyContent="space-between">
        <IFTypography variant="caption" ifcolor="text" fontWeight="bold">
          {t(
            hasWithdrawn
              ? 'Claimed'
              : isAirdropped
              ? 'Airdropped'
              : hasPurchased
              ? 'Total Purchased'
              : 'Total Allocation',
          )}
        </IFTypography>
        <IFTypography variant="button" ifcolor="text" fontWeight="bold" fontFamily="Roboto">
          {formatAmount(
            hasWithdrawn
              ? purchasedAmount
              : isAirdropped
              ? airdroppedAmount
              : hasPurchased
              ? purchasedAmount
              : estimatedAllocation,
            idoTokenSymbol || saleTokenDefaultName,
          )}
        </IFTypography>
      </Box>
    )
  }

  return (
    <ActionContainer>
      <Box display="flex" alignItems="center" justifyContent="center" gap="6px">
        <StrokeSale />
        <SaleEndTypo style={{ color: theme.colorsV2.saleEnds, alignContent: 'center' }}>{t('Sale ends')}</SaleEndTypo>
        <StrokeSale />
      </Box>
    </ActionContainer>
  )
}

const ActionButton: React.FC<HeaderProps> = ({ sale, isMobile = false }) => {
  const { isJustSale } = useContext(CompanyContext)
  const header = useMemo(() => {
    const now = new Date()

    if (isJustSale) {
      if (sale.isEnd) {
        return <Closed sale={sale} isMobile={isMobile} />
      }
      if (sale.isLive) {
        return <PurchaseNotEnd sale={sale as PurchasableIDO} />
      }
      if (sale.isUpcoming) {
        return <PurchaseNotEnd sale={sale as PurchasableIDO} />
      }
    }

    if (isSubscribeableIDO(sale as SubscribeableIDO)) {
      const {
        subscribePeriod: { startTime, endTime },
      } = sale as SubscribeableIDO

      if (now < new Date(startTime)) {
        return <SubscriptionNotStart sale={sale as SubscribeableIDO} />
      }
      if (now >= new Date(startTime) && now < new Date(endTime)) {
        return <SubscriptionStartNotEnd sale={sale as SubscribeableIDO} />
      }
    }
    if (isPurchaseableIDO(sale as PurchasableIDO)) {
      const {
        purchasePeriod: { endTime, startTime },
      } = sale as PurchasableIDO
      if (now < new Date(startTime)) {
        return <PurchaseNotStart sale={sale as PurchasableIDO} />
      }
      if (now < new Date(endTime)) {
        return <PurchaseNotEnd sale={sale as PurchasableIDO} />
      }
    }

    return <Closed sale={sale} isMobile={isMobile} />
  }, [sale])

  return <Container isMobile={isMobile}>{header}</Container>
}

export default ActionButton
