import { Box } from '@mui/material'
import { useAccount, useNetwork } from 'wagmi'
import BigNumber from 'bignumber.js'
import { CountdownProps } from 'componentsV2/Countdown/Countdown'
import NeedVerifyKYC from 'componentsV2/Icons/KYC/NeedVerify'
import Revoked from 'componentsV2/Icons/KYC/Revoked'

import WarningSquareIcon from 'componentsV2/Icons/WarningSquare'
import IFTypography from 'componentsV2/IFTypography/IFTypography'
import { MINIMUM_IDIA, MINIMUM_RON } from 'config/constants/kyc'
import tokens from 'config/constants/tokens'
import { useTranslation } from 'contexts/Localization'
import { useGetBalance } from 'hooks/useGetBalance'
import { useKycSession } from 'hooks/useKycSession'
import { useKycStatus } from 'hooks/useKycStatus'
import React, { useCallback, useContext, useMemo } from 'react'
import { ComplianceStatus, KYCSource, KYCStatus } from 'state/types'
import styled from 'styled-components'
import { Button, OpenNewIcon, useModalV2, Link } from 'uikit'
import { useAccountSidebar } from 'uikit/widgets/AccountSidebar/AccountSidebarContext'
import ModalV2 from 'uikit/widgets/Modal/ModalV2'
import { InjectedProps } from 'uikit/widgets/Modal/types'
import { getAddress } from 'utils/addressHelpers'
import useSignOAuth from 'hooks/useSignOAuth'
import { useIsUserSignedIn } from 'state/user/hooks'
import { getBalanceInWei } from 'utils/formatBalance'
import { Brands, CompanyContext } from 'contexts/CompanyContext'

import ComplianceCard from '../ComplianceCard'
import StyledCard from './StyledCard'

const Instruction = styled.ul`
  list-style-position: outside;
  padding: 10px 0px 10px 16px;
  background: ${({ theme }) => (theme.isDark ? `${theme.colorsV2?.textThirdly}0D` : theme.colorsV2?.dark2)};
  color: ${({ theme }) => (theme.isDark ? theme.colorsV2?.textThirdly : theme.colorsV2?.text)};

  margin: 20px 0;
  border-radius: 5px;
  font-size: 11px;
  line-height: 16px;

  li {
    margin: 5px 7.5px;
  }
`

const ContactSynapsWrap = styled.span`
  font-size: 11px;
  margin-top: 40px;
  line-height: 18px;
  font-weight: 400;
  text-align: center;
  color: ${({ theme }) => theme.colorsV2?.textSecondary};
  path {
    fill: ${({ theme }) => theme.colorsV2?.textLink};
  }
`

const CTAButton = styled(Button)<{ kycStatus: KYCStatus }>`
  width: 100%;
  ${({ kycStatus, theme }) =>
    (kycStatus === KYCStatus.PENDING || kycStatus === KYCStatus.PENDING_VERIFICATION) &&
    `color: ${theme.colorsV2.button.primaryText} !important; background:${theme.colorsV2.button.primaryBg} !important;  opacity: 0.5;`};
  ${({ kycStatus, theme }) =>
    kycStatus === KYCStatus.FINAL_REJECTED &&
    `color: ${theme.isDark ? theme.colorsV2.button.disabledText : theme.colorsV2.button.primaryText} !important;`};
`

interface SaleKYCStatusCardProps {
  countdownInfo?: CountdownProps
  kycStatus: KYCStatus
}

const InsufficientModal: React.FC<InjectedProps> = ({ onDismiss }) => {
  const { t } = useTranslation()
  const { brand } = useContext(CompanyContext)
  return (
    <ModalV2
      onDismiss={onDismiss}
      showConfirm
      bodyPadding="0px 0px 24px"
      confirmText={t('Go to Swap')}
      onConfirm={() => {
        window.open(`/swap`, '_blank')
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="center" textAlign="center">
        <WarningSquareIcon />
        <Box marginTop="40px" />
        <IFTypography variant="h5" ifcolor="text" fontWeight="bold">
          {t('Insuffcient Balance')}
        </IFTypography>
        <Box marginTop="20px" />
        <IFTypography variant="body2" ifcolor="textSecondary">
          {t('You must hold at least %min_token% %token_name% in your wallet to proceed with the KYC.', {
            min_token: brand === Brands.RONIN ? MINIMUM_RON : MINIMUM_IDIA,
            token_name: brand === Brands.RONIN ? 'RON' : 'IDIA',
          })}
        </IFTypography>
      </Box>
    </ModalV2>
  )
}

const SaleKYCStatusCard: React.FC<SaleKYCStatusCardProps> = ({ countdownInfo, kycStatus }) => {
  const { t } = useTranslation()
  const { onSession } = useKycSession()

  const { address: account } = useAccount()
  const { chain } = useNetwork()
  const chainId = chain?.id
  const isUserSigned = useIsUserSignedIn()
  const { brand, faqLink } = useContext(CompanyContext)
  const { signOAuth } = useSignOAuth('if')
  const { complianceStatus, kycSource } = useKycStatus()

  console.log(complianceStatus, kycSource, 'asdf')
  const ifBalance = useGetBalance(getAddress(tokens.if.address, chainId), account)
  const idiaBalance = useGetBalance(getAddress(tokens.idia.address, chainId), account)
  const hasMinimumIdia = idiaBalance.isGreaterThanOrEqualTo(getBalanceInWei(new BigNumber(MINIMUM_IDIA)))
  const { scrollToKYC } = useAccountSidebar()
  const [onPresent] = useModalV2({
    modal: <InsufficientModal />,
  })

  const isRevoked = kycStatus === KYCStatus.REVOKED

  const imageInfo = useMemo(() => {
    if (kycStatus === KYCStatus.REVOKED) {
      return {
        image: <Revoked />,
      }
    }
    return {
      image: <NeedVerifyKYC />,
    }
  }, [kycStatus])

  const instruction = useMemo(() => {
    if (isRevoked) {
      return (
        <>
          <Box marginTop={7} textAlign="center">
            <IFTypography variant="body2" ifcolor="textSecondary">
              The BAB Token (Binance Account Bound Token) has been revoked. To complete the KYC process with BAB Token,
              please ensure that the BAB Token is minted to your wallet.
            </IFTypography>
          </Box>
          <ContactSynapsWrap>
            If you have issues reconnecting the BAB Token, visit
            <Link
              external
              fontSize="10px !important"
              style={{ display: 'initial', paddingLeft: '3px' }}
              href="https://www.binance.com/en/support/faq/frequently-asked-questions-on-binance-account-bound-bab-token-adbd05fe149344d59a348f82d5bf359d"
            >
              How to proceed with Synaps or BAB Token
            </Link>
            <OpenNewIcon marginLeft={1} marginRight={1} />
            Or you would like to KYC with
            <Link
              external
              fontSize="10px !important"
              style={{ display: 'initial', paddingLeft: '3px' }}
              href="https://impossible.gitbook.io/impossible-finance-faq/start-here/get-started/verify-kyc-your-wallet"
            >
              Synaps
            </Link>
            <OpenNewIcon marginLeft={1} marginRight={1} />
            instead.
          </ContactSynapsWrap>
        </>
      )
    }
    return (
      <Instruction>
        <li>
          {t('This sale requires you to pass KYC. Your identity is tied to the connected wallet you use during KYC.')}
        </li>
        <li>
          {t('You must hold at least %min_token% %token_name% in your wallet to proceed with the KYC.', {
            min_token: brand === Brands.RONIN ? MINIMUM_RON : MINIMUM_IDIA,
            token_name: brand === Brands.RONIN ? 'RON' : 'IDIA',
          })}
        </li>
        <li>{t('You can only use a wallet that has passed KYC to subscribe, get allocation, purchase and claim.')}</li>
        <li>{t('KYC is not open to users from U.S., China, including Hong Kong, and sanctioned countries')}</li>
      </Instruction>
    )
  }, [t, isRevoked])

  const ctaBtnDisabled = useMemo(() => {
    return (
      kycStatus === KYCStatus.FINAL_REJECTED || kycStatus === KYCStatus.PENDING || kycStatus === KYCStatus.PENDING_POA
    )
  }, [kycStatus])

  const ctaHandler = useCallback(() => {
    if (ctaBtnDisabled) return
    onPresent()
  }, [ctaBtnDisabled, onSession, onPresent, hasMinimumIdia])

  const ctaInfo = useMemo(() => {
    let text = ''
    if (
      kycStatus === KYCStatus.SUBMISSION_REQUIRED ||
      kycStatus === KYCStatus.SUBMISSION_REQUIRED_POA ||
      kycStatus === KYCStatus.RESUBMISSION_REQUIRED
    ) {
      text = t('KYC needs submission')
    } else if (
      (kycStatus === KYCStatus.PENDING || kycStatus === KYCStatus.PENDING_POA) &&
      kycSource !== KYCSource.BABT
    ) {
      text = t('KYC is verifying')
    } else if (kycStatus === KYCStatus.FINAL_REJECTED || kycStatus === KYCStatus.REJECTED) {
      text = t('KYC is rejected')
    } else {
      if (process.env.REACT_APP_ENABLE_RECOMPLIANCE_BABT) {
        return {
          button: (
            <ComplianceCard
              type="button"
              disabled={ctaBtnDisabled}
              recompliance={complianceStatus === ComplianceStatus.EXPIRED}
              overrideOnPresent={hasMinimumIdia ? null : onPresent}
            />
          ),
        }
      }
      text = t('Verify KYC')
    }

    return {
      button: (
        <CTAButton kycStatus={kycStatus} disabled onClick={ctaHandler}>
          {text}
        </CTAButton>
      ),
    }
  }, [t, kycStatus, kycSource, ctaBtnDisabled, ctaHandler])

  const linkBtnInfo = useMemo(() => {
    const icon = <OpenNewIcon />
    if (kycStatus === KYCStatus.NOT_STARTED) {
      return {
        text: t('How to participate?'),
        icon,
        href: faqLink,
      }
    }
    return {
      text: t('Checking KYC status in your account'),
      icon,
      handler: scrollToKYC,
    }
  }, [t, kycStatus, scrollToKYC])

  if (kycStatus === KYCStatus.VERIFIED) {
    return null
  }
  return (
    <StyledCard
      countdownInfo={countdownInfo}
      imageInfo={imageInfo}
      title={t(isRevoked ? 'BAB Token Has Been Revoked' : 'Complete KYC to participate.')}
      ctaInfo={null}
      linkBtnInfo={!isRevoked ? linkBtnInfo : null}
      hidePostSaleInfo
    >
      {instruction}
    </StyledCard>
  )
}

export default SaleKYCStatusCard
